import {Injectable} from '@angular/core';
import {of as observableOf, Observable} from 'rxjs';
import {ProgressInfo, StatsProgressBarData} from '../data/stats-progress-bar';

@Injectable()
export class StatsProgressBarService extends StatsProgressBarData {
  private progressInfoData: ProgressInfo[] = [
    {
      title: 'Thanks',
      value: 200,
      activeProgress: 55,
      description: 'Better than last week (55%)',
    },
    {
      title: 'Perfect',
      value: 200,
      activeProgress: 55,
      description: 'Better than last week (55%)',
    },
    {
      title: 'N/A',
      value: 500,
      activeProgress: 70,
      description: 'Better than last week (70%)',
    },
  ];

  getProgressInfoData(): Observable<ProgressInfo[]> {
    return observableOf(this.progressInfoData);
  }
}
