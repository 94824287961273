import {Injectable} from '@angular/core';
import {NbDateService} from '@nebular/theme';

@Injectable()
export class WeekService {
  constructor(protected ds: NbDateService<Date>) {}

  getWeek(d) {
    const onejan = new Date(d.getFullYear(), 0, 1);
    const week = Math.ceil(((d.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7);
    return d.getFullYear() + '/' + week;
  }

  getWeeksBetween(start, end) {
    const weeks = [];
    const numWeeks = Math.round((end - start) / (7 * 24 * 60 * 60 * 1000));
    for (let num = 0; num < numWeeks; num++) {
      weeks.push(this.ds.addDay(start, 7 * num));
    }
    return weeks;
  }
}
