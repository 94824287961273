import {Component, OnInit, OnDestroy} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-support',
  templateUrl: './import-url.component.html',
  styleUrls: ['../support.styles.scss'],
})
export class ImportComponent implements OnInit, OnDestroy {
  constructor(protected ref: NbDialogRef<ImportComponent>) {}
  importURL: string = '';

  ngOnInit() {}

  save() {
    this.ref.close(this.importURL);
  }

  dismiss() {
    this.ref.close(null);
  }

  ngOnDestroy() {}
}
