<ng-container [formGroup]="form">
  <div style="width: 100%; align-items: center; display: flex">
    <nb-form-field style="width: 85%; display: inline-flex; margin-right: 5px">
      <nb-icon nbPrefix icon="file-audio" pack="fas"></nb-icon>
      <input type="text" nbInput fullWidth [formControlName]="controlName" placeholder="" />
    </nb-form-field>
    <button nbButton status="primary" style="width: 15%; display: inline-flex" (click)="openJSON()">
      <nb-icon icon="pen" pack="fas"></nb-icon>
    </button>
  </div>
  <div style="width: 100%; align-items: center; display: flex; margin-top: 5px">
    <audio crossorigin="anonymous" class="audioPlayer" controls [src]="getURL()"></audio>
    <!-- <audio class="audioPlayer" controls [src]="form.get(controlName).value"></audio> -->
  </div>
</ng-container>
