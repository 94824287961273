import {ClientActivity} from '../data/client-activity';
import {RestaurantActivity} from '../data/restaurant-activity';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor(private http: HttpClient) {}

  public proxyCover(operation: string, url: string, payload: Object): Observable<any> {
    if (operation === 'GET') return this.http.get(environment.api.url + '/proxy/cover/' + url);
    if (operation === 'POST') return this.http.get(environment.api.url + '/proxy/cover/' + url);
  }

  public proxyCoverObserveResponse(operation: string, url: string, payload: Object): Observable<HttpResponse<any>> {
    if (operation === 'GET') return this.http.get(environment.api.url + '/proxy/cover/' + url, {observe: 'response'});
    if (operation === 'POST') return this.http.get(environment.api.url + '/proxy/cover/' + url, {observe: 'response'});
  }

  public getRestooAvailability(merchant: string, payload: any): Observable<any> {
    return this.http.post(`https://proxy.dev.bookline.io/restoo/api/bookline/v2/${merchant}/availability/date`, payload, {
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        // 'x-restoo-api-key': 'LEL.UodZdBn7bqn.nD3jpEzididpCcyVWuiDf7G-jHgs*vhG9wMJKx7t8_MrDrDVyejvy2-UUsFKEyF-72Hdhz7*sCq4GNJ92UoG',
      },
    });
  }
  public getRestooReservations(merchant: string, payload: any): Observable<any> {
    return this.http.post(`https://proxy.dev.bookline.io/restoo/api/bookline/v2/${merchant}/bookings`, payload, {
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        // 'x-restoo-api-key': 'LEL.UodZdBn7bqn.nD3jpEzididpCcyVWuiDf7G-jHgs*vhG9wMJKx7t8_MrDrDVyejvy2-UUsFKEyF-72Hdhz7*sCq4GNJ92UoG',
      },
    });
  }
}
