<nb-card>
  <nb-card-header style="display: flex; justify-content: space-between; align-items: center"
    ><div>
      {{ title }}
    </div>
    <div>
      import from
      <nb-select style="width: 280px" [(ngModel)]="selectedScheduleToCopy">
        <nb-option *ngFor="let key of schedulesKeys" [value]="key">{{ key }}</nb-option>
      </nb-select>
      <button nbButton ghost status="primary" size="small" (click)="copySchedule()">apply</button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="scheduleForm">
      <div class="form-group row" *ngFor="let day of days" [formGroupName]="day">
        <label for="name" class="label col-2 col-form-label">{{ day }}</label>
        <div class="col-1">
          <nb-toggle formControlName="Open"></nb-toggle>
        </div>
        <div class="col-2 col-form-label" style="display: flex; flex-direction: column; align-items: flex-start">
          <label class="label">{{ getOpen(day).value ? 'Open' : 'Closed' }} </label>
          <button (click)="applyToAll(day)" style="padding: 7px 0" nbButton ghost size="tiny">Apply to all</button>
        </div>
        <div class="col-7" formArrayName="Slots" *ngIf="getOpen(day).value">
          <div class="row slot-row" *ngFor="let item of getSlots(day).controls; let slotIndex = index" [formGroupName]="slotIndex">
            <div class="col-3">
              <input [ngClass]="getFrom(day, slotIndex).invalid ? 'form-control is-invalid' : 'form-control'" type="text" fullWidth nbInput formControlName="from" />
            </div>
            <div class="sep">—</div>
            <div class="col-3">
              <input [ngClass]="getUntil(day, slotIndex).invalid ? 'form-control is-invalid' : 'form-control'" type="text" fullWidth nbInput formControlName="until" />
            </div>
            <button class="cancel-btn" nbButton ghost size="tiny" *ngIf="slotIndex != 0 || getSlots(day).controls.length > 1">
              <nb-icon icon="close" (click)="deleteSlot(day, slotIndex)"></nb-icon>
            </button>
            <button *ngIf="slotIndex == getSlots(day).controls.length - 1" nbButton ghost (click)="addSlot(day)">Add slot</button>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" [disabled]="unsubmittable" (click)="save()">Save</button>
    <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
  </nb-card-footer>
</nb-card>
