<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    <div class="form-group-row">
      <ng-select [items]="values" [closeOnSelect]="true" placeholder="Select an item" [(ngModel)]="selectedValue" fullWidth></ng-select>
    </div>
  </nb-card-body>
  <nb-card-footer style="display: flex; align-items: center; justify-content: space-between">
    <div>
      <button nbButton status="primary" (click)="confirmChoice()">Select</button>
      <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
    </div>
  </nb-card-footer>
</nb-card>
