<nb-card>
  <nb-card-header style="display: flex; justify-content: space-between; align-items: center">
    <div>New venue group</div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="mainForm">
      <div class="form-group">
        <!-- ID -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">ID<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <input type="text" nbInput fullWidth placeholder="ID" formControlName="id" disabled />
          </div>
        </div>
        <!-- Name -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Name<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <input type="text" nbInput fullWidth placeholder="Name" formControlName="name" autofocus />
          </div>
        </div>
        <!-- Owner id -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Customer ID<span class="mandatory">*</span></label>
          <div class="col-sm-7">
            <input #autoInput nbInput type="text" fullWidth formControlName="customer_id" (input)="onChange()" placeholder="Select customer id" [nbAutocomplete]="auto" />
            <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
              <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.id"> {{ option.name }} ({{ option.id }}) </nb-option>
            </nb-autocomplete>
          </div>
          <div *ngIf="!isCustomerSet()" class="col-sm-1">
            <button nbButton outline fullWidth (click)="createCustomer()"><nb-icon icon="plus-outline" status="info"></nb-icon></button>
          </div>
          <div *ngIf="isCustomerSet()" class="col-sm-1">
            <button nbButton outline fullWidth (click)="goToCustomer()"><nb-icon icon="external-link-outline" status="info"></nb-icon></button>
          </div>
        </div>
        <br />
        <!-- Venue IDs -->
        <div class="row slot-row" *ngFor="let venue of venueIdsFormArray.controls; let i = index" formArrayName="venue_ids">
          <label *ngIf="i == 0" class="label col-sm-3 col-form-label" for="">Venue IDs<span class="mandatory">*</span></label>
          <div *ngIf="i == 0 && !isValidCustomer()" class="col-sm-7">
            <nb-alert *ngIf="!isCustomerSet()" status="info">Select a valid customer_id with at least 2 venues</nb-alert>
            <nb-alert *ngIf="isCustomerSet() && customerVenues < 2" status="danger">This customer has {{ customerVenues }} venues</nb-alert>
          </div>

          <div *ngIf="isValidCustomer()" [class]="i != 0 ? 'col-sm-7 offset-md-3' : 'col-sm-7'" [formGroupName]="i">
            <input #autoVenueInput nbInput type="text" fullWidth formControlName="id" (input)="onVenueIdChange(i)" placeholder="Select venue id" [nbAutocomplete]="autoVenue" />
            <nb-autocomplete #autoVenue (selectedChange)="onVenueSelectionChange($event, i)">
              <nb-option *ngFor="let option of venueFilteredOptions$[i] | async" [value]="option.id"> {{ option.name }} ({{ option.id }}) </nb-option>
            </nb-autocomplete>
            <div class="form-group row">
              <label class="label col-sm-3 col-form-label" for="">Venue Name</label>
              <label class="label col-sm-9 col-form-label" style="font-weight: 600; color: black; display: flex; align-items: center">{{ venue_name(i) }}</label>
            </div>
          </div>
          <div *ngIf="isValidCustomer()" class="col-sm-2">
            <button *ngIf="i > 0" nbButton outline (click)="addVenue(i)"><nb-icon icon="plus-outline" status="info"></nb-icon></button>
            <button *ngIf="i > 1" nbButton outline style="margin-left: 5px" (click)="removeVenue(i)"><nb-icon icon="close-outline" status="danger"></nb-icon></button>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" (click)="save()" [disabled]="submitting" [nbSpinner]="submitting">Save</button>
    <button nbButton ghost status="primary" (click)="dismiss()" [disabled]="submitting">Cancel</button>
  </nb-card-footer>
</nb-card>
