import {inject, Injectable} from '@angular/core';
import {Auth, onAuthStateChanged, updateProfile} from '@angular/fire/auth';

@Injectable()
export class UserService {
  private auth: Auth = inject(Auth);

  constructor() {}

  // Obtener el usuario actual autenticado
  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  // Actualizar el perfil del usuario actual
  updateCurrentUser(value: {name: string; image_url?: string}) {
    return new Promise<any>((resolve, reject) => {
      const user = this.auth.currentUser;

      if (user) {
        updateProfile(user, {
          displayName: value.name,
          photoURL: value.image_url ? value.image_url : user.photoURL,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject('No user is currently logged in');
      }
    });
  }
}
