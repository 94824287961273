import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {PollyClient, DeleteLexiconCommand, VoiceId, Engine, LanguageCode, OutputFormat, TextType, SynthesizeSpeechCommand} from '@aws-sdk/client-polly';

@Injectable({
  providedIn: 'root',
})
export class ProxyService {
  constructor(private http: HttpClient) {}

  getLangCode(language) {
    switch (language) {
      case 'SPANISH':
        return 'es-ES';

      case 'CATALAN':
        return 'ca-ES';

      case 'MEXICAN':
        return 'es-MX';

      case 'URUGUAYAN':
        return 'es-UY';
      case 'PARAGUAYAN':
        return 'es-PY';

      case 'COLOMBIAN':
        return 'es-CO';

      case 'ENGLISH':
        return 'en-US';

      case 'BRITISH':
        return 'en-GB';

      case 'GERMAN':
        return 'de-DE';

      case 'FRENCH':
        return 'fr-FR';

      case 'PORTUGUESE':
        return 'pt-PT';

      case 'ITALIAN':
        return 'it-IT';

      case 'SAUDIARABIAN':
        return 'ar-SA';

      case 'TURKISH':
        return 'tr-TR';

      case 'ROMANIAN':
        return 'ro-RO';

      case 'NORWEGIAN':
        return 'nb-NO';

      default:
        return 'es-ES';
    }
  }

  public async generateElevenLabsVoice(voice_id: string, text: string, model?: string): Promise<any> {
    const payload = {text: text, model_id: model ?? 'eleven_multilingual_v2'};

    const options = {
      method: 'POST',
      headers: {
        'xi-api-key': '1b253aa3141c4666c31395e789635abb',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch('https://api.elevenlabs.io/v1/text-to-speech/' + voice_id, options);

      return response;
    } catch (err) {
      console.error('Error generating voice with ElevenLabs:', err);
      throw err;
    }
  }
  public async getElevenLabsVoices(): Promise<any> {
    const options = {method: 'GET', headers: {'xi-api-key': '1b253aa3141c4666c31395e789635abb'}};

    const response = await fetch('https://api.elevenlabs.io/v1/voices', options);

    return response.json();
  }

  public async generateAmazonVoice(voice: any): Promise<any> {
    const client: any = new PollyClient({region: 'eu-west-3', credentials: {accessKeyId: 'AKIA5RVTXQUD5XSZBRAN', secretAccessKey: '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'}});
    const params: any = {
      VoiceId: voice.code,
      OutputFormat: 'mp3',
      LanguageCode: this.getLangCode(voice.language),
      Text: voice.tts,
      TextType: 'text',
      Engine: voice.neural === true ? 'neural' : 'standard',
    };
    const command = new SynthesizeSpeechCommand(params);
    const response = await client.send(command);
    return response;
  }

  public generateMicrosoftVoice(voice: any): Observable<any> {
    const payload = `<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="${this.getLangCode(
      voice.language
    )}"><voice name="${voice.code}" xml:lang="${this.getLangCode(voice.language)}" xml:gender="${voice.gender === 'F' ? 'Female' : 'Male'}">${voice.tts}</voice></speak>`;

    return this.http.post('https://proxy.dev.bookline.io/microsoft_texttospeech/cognitiveservices/v1', payload, {
      headers: {
        'Content-Type': 'application/ssml+xml',
        'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3',
      },
      responseType: 'arraybuffer',
    });
  }

  public generateGoogleVoice(voice: any): Observable<any> {
    const payload = {
      audioConfig: {
        audioEncoding: 'LINEAR16',
        effectsProfileId: ['telephony-class-application'],
        pitch: '0.00',
        speakingRate: '1.2',
      },
      input: {
        text: voice.tts,
      },
      voice: {
        languageCode: this.getLangCode(voice.language),
        name: voice.code,
      },
    };

    // return this.http.post("https://en1sgrnut1dpi.x.pipedream.net/v1/text:synthesize", payload, {
    return this.http.post('https://proxy.dev.bookline.io/google_texttospeech/v1/text:synthesize', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
      // withCredentials: true,
    });
  }

  public getFirebaseStorage(endopoint: any): Observable<any> {
    return this.http.get('https://proxy.dev.bookline.io/firebase-storage/' + endopoint);
  }
  public getGooglePlaces(query: any): Observable<any> {
    return this.http.get(
      `https://proxy.dev.bookline.io/google_maps/maps/api/place/textsearch/json?query=${query}&inputtype=textquery&location=41.3853922%2C2.1698379&key=AIzaSyCbZZmO5SFB8zZAVGZGb3FJantWYIRkqcM&fields=formatted_phone_number,formatted_address,name,type`
    );
  }
  public getGoogleData(place_id: string): Observable<any> {
    return this.http.get(
      `https://proxy.dev.bookline.io/google_maps/maps/api/place/details/json?place_id=${place_id}&key=AIzaSyCbZZmO5SFB8zZAVGZGb3FJantWYIRkqcM&fields=formatted_phone_number,formatted_address,name,current_opening_hours,formatted_phone_number,international_phone_number,opening_hours,secondary_opening_hours,website`
    );
  }
  public getWabaTemplates(waba_account_id: string): Observable<any> {
    return this.http.get(`https://proxy.dev.bookline.io/360d_partner/api/v2/partners/JlKl7HPA/waba_accounts/${waba_account_id}/waba_templates`);
  }
}
