<nb-card style="width: 700px">
  <nb-card-header>Import metadata from URL</nb-card-header>
  <nb-card-body>
    <input nbInput [(ngModel)]="importURL" fullWidth placeholder="Paste URL to import here" />
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="basic" (click)="dismiss()">Cancel</button>
    <button nbButton status="danger" (click)="save()">Import</button>
  </nb-card-footer>
</nb-card>
