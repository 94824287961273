import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {IdNameData, Venue, PlatformService} from '../../../@core/api/platform.service';
import {defaultVenue, profileToFormGroup, extractSalesforceIdFromUrl, profileFromFormGroup} from './utils';
import {NewCustomerDialogComponent} from '../new-customer/new-customer-dialog.component';

@Component({
  selector: 'ngx-new-venue-dialog',
  templateUrl: 'new-venue-dialog.component.html',
  styleUrls: ['new-venue-dialog.component.scss'],
})
export class NewVenueDialogComponent implements OnInit, OnDestroy {
  @Input() input_customer_id?: string;
  @Input() proposed_name?: string;
  @ViewChild('autoInput') autoInput;
  constructor(
    protected ref: NbDialogRef<NewVenueDialogComponent>,
    private fb: UntypedFormBuilder,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private papi: PlatformService
  ) {}

  pattern = '([01]?[0-9]|2[0123]):([012345][0-9])';
  createMode: boolean = false;
  expertMode: boolean = false;
  submitting: boolean = false;
  showForm: boolean = true;
  venue: Venue;
  mainForm: UntypedFormGroup;
  scheduleForm: UntypedFormGroup;
  filteredOptions$: Observable<IdNameData[]>;

  papi_customer_ids_and_names: IdNameData[] = [];
  papi_customer_ids: string[] = [];

  venueTypes: string[] = ['RESTAURANT', 'HOTEL', 'CAMPING'];

  async ngOnInit() {
    this.venue = defaultVenue();
    this.mainForm = profileToFormGroup(this.venue, this.fb);
    this.papi_customer_ids_and_names = await this.papi.getAllCustomerIdsAndNames();
    this.papi_customer_ids = this.papi_customer_ids_and_names.map((cust) => cust.id);
    this.filteredOptions$ = of(this.papi_customer_ids_and_names);

    if (this.input_customer_id != null && this.papi_customer_ids.includes(this.input_customer_id)) {
      this.mainForm.get('customer_id').setValue(this.input_customer_id);
    }
    if (this.proposed_name != null && this.proposed_name.trim() != '') {
      this.mainForm.get('name').setValue(this.proposed_name);
    }
    this.mainForm.get('crm_id')?.valueChanges.subscribe((value) => {
      this.mainForm.get('crm_id')?.setValue(extractSalesforceIdFromUrl(value), {emitEvent: false});
    });
  }

  async save() {
    let profile: Venue = undefined;
    try {
      profile = profileFromFormGroup(this.mainForm);
    } catch (error) {
      this.toastrService.danger('Message: ' + error.message, 'Invalid data.');
      return;
    }
    // TODO sanity checks
    this.submitting = true;
    let message = 'Venue created!';
    try {
      const response = await this.papi.addVenue(profile).toPromise();
      if (response.status === 201) {
        message = 'Venue created!';
      }
    } catch (error: any) {
      if (error instanceof HttpErrorResponse) {
        this.toastrService.danger('Status: ' + error.status + ' message: ' + error.message, 'Unknown error creating venue.');
        return;
      } else {
        this.toastrService.danger(error, 'Unknown error updating venue.');
        return;
      }
    } finally {
      this.submitting = false;
    }
    this.toastrService.success(message, 'Success!');
    this.ref.close({
      success: true,
      data: profile,
    });
  }

  private filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.papi_customer_ids_and_names?.filter((optionValue) => optionValue.id.toLowerCase()?.includes(filterValue) || optionValue.name.toLowerCase()?.includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<any[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  onChange() {
    if (this.papi_customer_ids?.includes(this.autoInput?.nativeElement.value)) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
    this.filteredOptions$ = this.getFilteredOptions(this.autoInput?.nativeElement.value);
  }

  onSelectionChange($event) {
    if (this.papi_customer_ids?.includes(this.autoInput?.nativeElement.value)) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
    this.filteredOptions$ = this.getFilteredOptions($event);
  }

  isVenueSet() {
    return this.showForm;
  }

  createCustomer() {
    this.dialogService
      .open(NewCustomerDialogComponent, {
        context: {},
      })
      .onClose.subscribe((result) => {
        console.log(result);
        if (result.success) {
          this.papi_customer_ids.push(result.data.id);
          this.papi_customer_ids_and_names.push({id: result.data.id, name: result.data.name});
          this.mainForm.get('customer_id').setValue(result.data.id);
        }
      });
  }

  goToCustomer() {
    const customer_id = this.mainForm.get('customer_id').value;
    if (customer_id) {
      window.open('/customers/' + customer_id);
    }
  }

  dismiss() {
    this.ref.close({
      success: false,
      data: null,
    });
  }

  ngOnDestroy() {}
}
