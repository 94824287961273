import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';

@Component({
  selector: 'ngx-edit-create-integration-dialog',
  templateUrl: './edit-create-integration-dialog.component.html',
  styleUrls: ['./edit-create-integration-dialog.component.scss'],
})
export class EditCreateIntegrationDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() value?: any;

  integrationStringValue: string;
  integrationMode: 'id' | 'json';
  expandedTextAreas: string[] = [];

  constructor(protected ref: NbDialogRef<EditCreateIntegrationDialogComponent>, private toastrService: NbToastrService) {}

  ngOnInit() {
    if (this.value == null) {
      this.integrationStringValue = '';
      this.integrationMode = 'id';
    } else if (typeof this.value === 'string' || this.value instanceof String) {
      this.integrationStringValue = this.value.toString();
      this.integrationMode = 'id';
    } else {
      this.integrationStringValue = JSON.stringify(this.value, null, 2);
      this.integrationMode = 'json';
    }
  }

  onChangeModeToggle($event: boolean) {
    if ($event) {
      this.integrationMode = 'json';
    } else {
      this.integrationMode = 'id';
    }
  }

  isExpandedTextArea(element_id: string): boolean {
    if (!Object.keys(this.expandedTextAreas).includes(element_id)) {
      this.expandedTextAreas[element_id] = false;
    }
    return this.expandedTextAreas[element_id];
  }

  setExpandedTextArea(element_id: string, value: boolean): void {
    this.expandedTextAreas[element_id] = value;
  }

  toggleTextareaExpanded(element_id: string): void {
    let textarea = null;
    try {
      textarea = document.getElementById(element_id);
    } catch (exception) {
      console.log('Cannot get html element with id: ' + element_id, exception);
      return;
    }
    if (textarea == null) {
      console.log('Cannot get html element with id: ' + element_id + ' is null');
      return;
    }
    // Toggle expanded
    this.setExpandedTextArea(element_id, !this.isExpandedTextArea(element_id));
    // Adjust HTML
    if (this.isExpandedTextArea(element_id)) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      textarea.style.overflowY = 'hidden';
    } else {
      textarea.style.height = 'auto';
      textarea.style.overflowY = 'auto';
    }
  }

  save() {
    let final_value: any;
    if (this.integrationMode == 'json') {
      try {
        final_value = JSON.parse(this.integrationStringValue);
      } catch {
        this.toastrService.warning('JSON seems invalid', 'Unable to save');
        return;
      }
    } else {
      try {
        final_value = JSON.parse(this.integrationStringValue);
        this.toastrService.warning('ID seems invalid', 'Unable to save');
        return;
      } catch {
        final_value = this.integrationStringValue;
      }
    }
    this.ref.close(final_value);
  }

  dismiss() {
    this.ref.close(null);
  }

  ngOnDestroy() {}
}
