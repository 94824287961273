import {Component, Input, OnInit, Output, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {ControlContainer, UntypedFormGroup, FormGroupDirective, FormArray} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {ProxyService} from '../../../@core/api/proxy.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../../@core/firestore/user.service';
import {createFFmpeg, fetchFile} from '@ffmpeg/ffmpeg';
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from '@angular/fire/storage';
import {User} from '@angular/fire/auth';

@Component({
  selector: 'ngx-image-input',
  styleUrls: ['./image-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './image-input.component.html',
})
export class ImageInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() formGroup: UntypedFormGroup;

  form: UntypedFormGroup;
  proxy_subs: Subscription;
  audioURL: string = '';
  storage: any;
  uploadLoading: boolean = false;
  currentUser: User;
  uploadedFileURL: string = '';

  constructor(public controlContainer: ControlContainer, public ps: ProxyService, private userService: UserService) {}

  ngOnInit() {
    this.storage = getStorage(); // Obtenemos la instancia de Storage

    this.userService
      .getCurrentUser()
      .then((user) => {
        this.currentUser = user;
      })
      .catch((err) => console.log(err));

    if (this.formGroup != null) {
      this.form = this.formGroup;
    } else {
      this.form = <UntypedFormGroup>this.controlContainer.control;
    }
  }

  handleUploadFile(event: any) {
    const file: File = event.target.files[0];
    // this.createAudioBlobFromFile(file);
    console.log('FILE >>', file);
    this.uploadFile(file);
  }

  onDrop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    console.log('file>>>>', file);
    console.log('storage>>>>', this.storage);

    this.uploadFile(file);
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  uploadFile1(file: File) {
    this.uploadLoading = true;

    // Crear una referencia al archivo en el almacenamiento
    const filePath = `${this.currentUser.email}/${file.type}/${Date.now().toString().slice(-3)}-${file.name}`;
    const storageRef = ref(this.storage, filePath);

    // Subir el archivo usando `uploadBytesResumable` para manejar el progreso de la carga
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Manejar los estados de la carga del archivo
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Aquí puedes manejar el progreso si lo necesitas
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Error uploading file:', error);
        this.uploadLoading = false;
      },
      async () => {
        // Al completarse la carga, obtener la URL de descarga
        try {
          const url = await getDownloadURL(storageRef);
          console.log('Archivo subido:', url);
          this.uploadedFileURL = url;
          this.form.get(this.controlName).setValue(url);
        } catch (error) {
          console.error('Error obteniendo la URL de descarga:', error);
        } finally {
          this.uploadLoading = false;
        }
      }
    );
  }

  async uploadFile2(file: File) {
    this.uploadLoading = true;

    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg';
    let fileName = Date.now().toString().slice(-3) + '-' + file.name;
    let fileUtil = file;

    if (!isJpg) {
      // Inicialización y procesamiento con FFmpeg
      const ffmpeg = createFFmpeg({log: true});
      if (!ffmpeg.isLoaded()) await ffmpeg.load();

      ffmpeg.FS('writeFile', file.name, await fetchFile(file)); // Escribir el archivo en el FS de FFmpeg
      await ffmpeg.run('-i', file.name, 'output.jpeg'); // Convertir el archivo a JPEG

      // Leer el archivo convertido y crear un Blob
      const outputData = ffmpeg.FS('readFile', 'output.jpeg');
      fileUtil = new File([outputData.buffer], 'output.jpeg', {type: 'image/jpeg'}); // Convertir a archivo
      fileName = fileName.split('.')[0] + '.jpeg';
    }

    // Crear la referencia al archivo en el almacenamiento
    const filePath = `${this.currentUser.email}/image/${fileName}`;
    const storageRef = ref(this.storage, filePath); // Usar el método modular `ref`

    // Subir el archivo
    const uploadTask = uploadBytesResumable(storageRef, fileUtil); // Usar `uploadBytesResumable` para manejar la subida

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Error uploading file:', error);
        this.uploadLoading = false;
      },
      async () => {
        // Obtener la URL de descarga después de la subida
        try {
          const url = await getDownloadURL(storageRef); // Obtener la URL de descarga
          console.log('ARCHIVO SUBIDO:', url);
          this.uploadedFileURL = url;
          this.form.get(this.controlName).setValue(url);
        } catch (error) {
          console.error('Error obteniendo la URL de descarga:', error);
        } finally {
          this.uploadLoading = false;
        }
      }
    );
  }
  async uploadFile(file: File) {
    this.uploadLoading = true;

    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg';
    let fileName = Date.now().toString().slice(-3) + '-' + file.name;
    let fileUtil = file;

    // Conversión si no es JPG
    if (!isJpg) {
      const ffmpeg = createFFmpeg({log: true});
      if (!ffmpeg.isLoaded()) await ffmpeg.load();

      ffmpeg.FS('writeFile', file.name, await fetchFile(file));
      await ffmpeg.run('-i', file.name, '-vf', "format=yuva444p,geq='if(lte(alpha(X,Y),16),255,p(X,Y))':'if(lte(alpha(X,Y),16),128,p(X,Y))':'if(lte(alpha(X,Y),16),128,p(X,Y))'", 'output.jpeg');

      const outputData = ffmpeg.FS('readFile', 'output.jpeg');
      fileUtil = new File([outputData.buffer], 'output.jpeg', {type: 'image/jpeg'});
      fileName = fileName.split('.')[0] + '.jpeg';
    }

    // Crear una referencia al archivo en Firebase Storage
    const filePath = `${this.currentUser.email}/image/${fileName}`;
    const storageRef = ref(this.storage, filePath); // Usar la función `ref` correctamente

    // Subir el archivo
    const uploadTask = uploadBytesResumable(storageRef, fileUtil);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Error uploading file:', error);
        this.uploadLoading = false;
      },
      async () => {
        try {
          const url = await getDownloadURL(storageRef);
          console.log('ARCHIVO SUBIDO:', url);
          this.uploadedFileURL = url;
          this.form.get(this.controlName).setValue(url);
        } catch (error) {
          console.error('Error obteniendo la URL de descarga:', error);
        } finally {
          this.uploadLoading = false;
        }
      }
    );
  }

  getURL() {
    return this.form
      .get(this.controlName)
      .value.replace('https://firebasestorage.googleapis.com', 'https://proxy.dev.bookline.io/firebase_storage')
      .replace('https://storage.googleapis.com', 'https://proxy.dev.bookline.io/storage_google');
  }
}
