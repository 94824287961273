<nb-card>
  <nb-card-header>Edit Service for Bot: {{ botName }} ({{ botId }})</nb-card-header>
  <nb-card-body [nbSpinner]="loading">
    <form [formGroup]="serviceForm">
      <div class="form-group row">
        <label class="label col-sm-2 col-form-label">Customer ID</label>
        <div class="col-sm-10">
          <input type="text" fullWidth nbInput formControlName="customer_id" readonly />
        </div>
      </div>
      <div class="form-group row">
        <label class="label col-sm-2 col-form-label">Account</label>
        <div class="col-sm-10">
          <nb-select (selectedChange)="setParentAccount()" fullWidth placeholder="Account Id" formControlName="account_id">
            <nb-option *ngFor="let account_data of customerAccountsData" [value]="account_data.id">{{ account_data.name }} ({{ account_data.id }})</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="label col-sm-2 col-form-label">Bot & Business Line</label>
        <div class="col-sm-5">
          <nb-select fullWidth placeholder="Bot ID" formControlName="bot_id" (selectedChange)="updateBotId()">
            <nb-option *ngFor="let bot_data of customerBotsData" [value]="bot_data.id">{{ bot_data.name }} ({{ bot_data.id }})</nb-option>
          </nb-select>
        </div>
        <div class="col-sm-5" formGroupName="service">
          <nb-select fullWidth placeholder="Business Line" formControlName="business_line">
            <nb-option *ngFor="let available_bl of businessLines" [value]="available_bl">{{ available_bl }}</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-group row" formGroupName="service">
        <label class="label col-sm-2 col-form-label">Service Type & Status</label>
        <div class="col-sm-5">
          <nb-select fullWidth placeholder="VOICE ASSISTANT" formControlName="service_type">
            <nb-option *ngFor="let available_type of serviceTypes" [value]="available_type">{{ available_type }}</nb-option>
          </nb-select>
        </div>
        <div class="col-sm-5">
          <nb-select fullWidth placeholder="DRAFT" formControlName="status">
            <nb-option *ngFor="let available_status of serviceStatuses" [value]="available_status">{{ available_status }}</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-group row" formGroupName="service">
        <div class="col-sm-2">
          <div class="form-group row">
            <label class="label col-sm-8 col-form-label">Price History</label>
            <div class="col-sm-4">
              <button nbButton ghost (click)="addFirstPriceLine()" style="padding-left: 0; padding-right: 0"><nb-icon icon="plus-square-outline" status="success"></nb-icon></button>
            </div>
          </div>
        </div>
        <div class="col-sm-10">
          <div class="form-group" formArrayName="price_history">
            <nb-list>
              <nb-list-item *ngFor="let history_line of priceHistoryFormArray.controls; let i = index">
                <div [formGroupName]="i" class="form-group row">
                  <div class="col-sm-4">
                    <div class="row mb-2">
                      <label class="col-sm-4 col-form-label"><b>From: </b></label>
                      <div class="col-sm-8" nbTooltip="Start Date of this price" nbTooltipPlacement="bottom">
                        <input [nbDatepicker]="pricestartdate" type="text" nbInput fullWidth placeholder="Start date" formControlName="start_date" />
                        <nb-datepicker #pricestartdate format="dd/MM/yyyy"></nb-datepicker>
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-sm-4 col-form-label"><b>To: </b></label>
                      <div class="col-sm-8" nbTooltip="End Date of this price - must be set if there is a newer price" nbTooltipPlacement="bottom">
                        <input [nbDatepicker]="priceenddate" type="text" nbInput fullWidth placeholder="End date" formControlName="end_date" />
                        <nb-datepicker #priceenddate format="dd/MM/yyyy"></nb-datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-2">
                      <label class="col-sm-4 col-form-label" style="text-align: right"
                        ><b>{{ history_line.get('pricex100').value - history_line.get('discountx100').value | number: '1.2-2' }}€ = </b></label
                      >
                      <div class="col-sm-4" nbTooltip="Price" nbTooltipPlacement="bottom">
                        <nb-form-field style="width: 100%">
                          <input type="number" fullWidth nbInput formControlName="pricex100" />
                        </nb-form-field>
                      </div>
                      <div class="col-sm-4" nbTooltip="Discount" nbTooltipPlacement="bottom">
                        <nb-form-field style="width: 100%">
                          <input type="number" fullWidth nbInput formControlName="discountx100" />
                        </nb-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-8" nbTooltip="Description" nbTooltipPlacement="bottom">
                        <nb-form-field style="width: 100%">
                          <nb-icon nbPrefix icon="sticky-note" pack="fas" style="font-size: 1.2rem !important"></nb-icon>
                          <input type="text" fullWidth nbInput formControlName="description" />
                        </nb-form-field>
                      </div>
                      <div class="col-sm-4">
                        <nb-toggle
                          formControlName="billable"
                          nbTooltip="set to PAY if the customer should be charged for the service during this period - will generate an Invoice"
                          nbTooltipPlacement="bottom"
                          >{{ history_line.controls.billable.value ? 'Pay' : 'Free' }}</nb-toggle
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="row mb-2">
                      <div class="col-sm-12">
                        <button nbButton ghost (click)="removePriceLine(i)"><nb-icon icon="close-square-outline" status="danger"></nb-icon></button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <button nbButton ghost (click)="appendPriceLine(i)"><nb-icon icon="plus-square-outline" status="success"></nb-icon></button>
                      </div>
                    </div>
                  </div>
                </div>
              </nb-list-item>
            </nb-list>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" (click)="save()">Update</button>
    <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
  </nb-card-footer>
</nb-card>
