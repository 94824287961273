<ng-container [formGroup]="form">
  <div class="row">
    <div class="col-8">
      <div style="width: 100%; align-items: center; display: flex" *ngIf="mapModeToggle">
        <nb-form-field style="width: 90%; display: inline-flex; margin-right: 5px">
          <nb-icon nbPrefix icon="language" pack="fas"></nb-icon>
          <input type="text" nbInput fullWidth [formControlName]="controlName" placeholder="" type="hidden" />
          <input type="text" nbInput fullWidth [value]="selectedLanguageValue" [readonly]="true" [placeholder]="placeholderText" />
        </nb-form-field>
        <button nbButton status="primary" style="width: 10%; display: inline-flex" (click)="openLanguageMapStringEditor()">
          <nb-icon icon="pen" pack="fas"></nb-icon>
        </button>
      </div>
      <div style="width: 100%; align-items: center; display: flex" *ngIf="!mapModeToggle">
        <nb-form-field style="width: 100%; display: inline-flex; margin-right: 5px">
          <input type="text" nbInput fullWidth [formControlName]="controlName" [placeholder]="placeholderText" />
        </nb-form-field>
      </div>
    </div>
    <div class="col-4">
      <nb-toggle (checkedChange)="onChangeModeToggle($event)" [checked]="mapModeToggle">{{ mapModeToggle ? 'Map' : 'Single' }}</nb-toggle>
    </div>
  </div>
</ng-container>
