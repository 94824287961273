import {LanguageMap} from '../../../@core/data/model-utils.model';

export const VOLUME_OPTIONS: string[] = ['default', 'x-soft', 'soft', 'medium', 'loud', 'x-loud'];

export const ELEVENLABS_VOICES: LanguageMap<string[]> = {
  ENGLISH: [
    'Rachel',
    'Drew',
    'Clyde',
    'Paul',
    'Domi',
    'Dave',
    'Fin',
    'Sarah',
    'Antoni',
    'Thomas',
    'Charlie',
    'George',
    'Emily',
    'Elli',
    'Callum',
    'Patrick',
    'Harry',
    'Liam',
    'Dorothy',
    'Josh',
    'Arnold',
    'Charlotte',
    'Alice',
    'Matilda',
    'Matthew',
    'James',
    'Joseph',
    'Jeremy',
    'Michael',
    'Ethan',
    'Chris',
    'Gigi',
    'Freya',
    'Brian',
    'Grace',
    'Daniel',
    'Lily',
    'Serena',
    'Adam',
    'Nicole',
    'Bill',
    'Jessie',
    'Sam',
    'Glinda',
    'Giovanni',
    'Mimi',
  ],
  SPANISH: ['Nina'],
};

export const MICROSOFT_VOICES: LanguageMap<string[]> = {
  SPANISH: [
    'es-ES-AbrilNeural',
    'es-ES-AlvaroNeural',
    'es-ES-ArnauNeural',
    'es-ES-DarioNeural',
    'es-ES-EliasNeural',
    'es-ES-ElviraNeural',
    'es-ES-EstrellaNeural',
    'es-ES-IreneNeural',
    'es-ES-LaiaNeural',
    'es-ES-LiaNeural',
    'es-ES-NilNeural',
    'es-ES-SaulNeural',
    'es-ES-TeoNeural',
    'es-ES-TrianaNeural',
    'es-ES-VeraNeural',
  ],
  MEXICAN: [
    'es-MX-BeatrizNeural',
    'es-MX-CandelaNeural',
    'es-MX-CarlotaNeural',
    'es-MX-CecilioNeural',
    'es-MX-DaliaNeural',
    'es-MX-GerardoNeural',
    'es-MX-JorgeNeural',
    'es-MX-LarissaNeural',
    'es-MX-LibertoNeural',
    'es-MX-LucianoNeural',
    'es-MX-MarinaNeural',
    'es-MX-NuriaNeural',
    'es-MX-PelayoNeural',
    'es-MX-RenataNeural',
    'es-MX-YagoNeural',
  ],
  URUGUAYAN: ['es-UY-MateoNeural', 'es-UY-ValentinaNeural'],
  CATALAN: ['ca-ES-AlbaNeural', 'ca-ES-JoanaNeural', 'ca-ES-EnricNeural'],
  COLOMBIAN: ['es-CO-SalomeNeural', 'es-CO-GonzaloNeural'],
  ENGLISH: [
    'en-US-AmberNeural',
    'en-US-AnaNeural',
    'en-US-AriaNeural',
    'en-US-AshleyNeural',
    'en-US-BrandonNeural',
    'en-US-ChristopherNeural',
    'en-US-CoraNeural',
    'en-US-DavisNeural',
    'en-US-ElizabethNeural',
    'en-US-EricNeural',
    'en-US-GuyNeural',
    'en-US-JacobNeural',
    'en-US-JaneNeural',
    'en-US-JasonNeural',
    'en-US-JennyNeural',
    'en-US-MichelleNeural',
    'en-US-MonicaNeural',
    'en-US-NancyNeural',
    'en-US-RogerNeural',
    'en-US-SaraNeural',
    'en-US-SteffanNeural',
    'en-US-TonyNeural',
  ],
  BRITISH: [
    'en-GB-SoniaNeural',
    'en-GB-RyanNeural',
    'en-GB-LibbyNeural',
    'en-GB-AbbyNeural',
    'en-GB-AlfieNeural',
    'en-GB-BellaNeural',
    'en-GB-ElliotNeural',
    'en-GB-EthanNeural',
    'en-GB-HollieNeural',
    'en-GB-MaisieNeural',
    'en-GB-NoahNeural',
    'en-GB-OliverNeural',
    'en-GB-OliviaNeural',
    'en-GB-ThomasNeural',
  ],
  FRENCH: [
    'fr-FR-AlainNeural',
    'fr-FR-BrigitteNeural',
    'fr-FR-CelesteNeural',
    'fr-FR-ClaudeNeural',
    'fr-FR-CoralieNeural',
    'fr-FR-DeniseNeural',
    'fr-FR-EloiseNeural',
    'fr-FR-HenriNeural',
    'fr-FR-JacquelineNeural',
    'fr-FR-JeromeNeural',
    'fr-FR-JosephineNeural',
    'fr-FR-MauriceNeural',
    'fr-FR-YvesNeural',
    'fr-FR-YvetteNeural',
  ],
  GERMAN: [
    'de-DE-AmalaNeural',
    'de-DE-BerndNeural',
    'de-DE-ChristophNeural',
    'de-DE-ConradNeural',
    'de-DE-ElkeNeural',
    'de-DE-GiselaNeural',
    'de-DE-KasperNeural',
    'de-DE-KatjaNeural',
    'de-DE-KillianNeural',
    'de-DE-KlarissaNeural',
    'de-DE-KlausNeural',
    'de-DE-LouisaNeural',
    'de-DE-MajaNeural',
    'de-DE-RalfNeural',
    'de-DE-TanjaNeural',
  ],
  PORTUGUESE: ['pt-PT-DuarteNeural', 'pt-PT-FernandaNeural', 'pt-PT-RaquelNeural'],
  ITALIAN: [
    'it-IT-ElsaNeural',
    'it-IT-IsabellaNeural',
    'it-IT-DiegoNeur',
    'it-IT-BenignoNeur',
    'it-IT-CalimeroNeur',
    'it-IT-CataldoNeur',
    'it-IT-FabiolaNeural',
    'it-IT-FiammaNeural',
    'it-IT-GianniNeur',
    'it-IT-ImeldaNeural',
    'it-IT-IrmaNeural',
    'it-IT-LisandroNeur',
    'it-IT-PalmiraNeural',
    'it-IT-PierinaNeural',
    'it-IT-RinaldoNeur',
    'it-IT-Cosimo',
    'it-IT-LuciaRUS',
  ],
  SAUDIARABIAN: ['ar-SA-HamedNeural', 'ar-SA-ZariyahNeural'],
  TURKISH: ['tr-TR-AhmetNeural', 'tr-TR-EmelNeural'],
  ROMANIAN: ['ro-RO-AlinaNeural', 'ro-RO-EmilNeural'],
  NORWEGIAN: ['nb-NO-PernilleNeural', 'nb-NO-FinnNeural', 'nb-NO-IselinNeural'],
};

export const GOOGLE_VOICES: LanguageMap<string[]> = {
  SPANISH: [
    'es-ES-Neural2-A',
    'es-ES-Neural2-B',
    'es-ES-Neural2-C',
    'es-ES-Neural2-D',
    'es-ES-Neural2-E',
    'es-ES-Neural2-F',
    'es-ES-Wavenet-B',
    'es-ES-Wavenet-C',
    'es-ES-Wavenet-D',
    'es-ES-Standard-A',
    'es-ES-Standard-B',
    'es-ES-Standard-C',
    'es-ES-Standard-D',
  ],
  CATALAN: ['ca-es-Standard-A'],
  ENGLISH: [
    'en-US-Neural2-A',
    'en-US-Neural2-C',
    'en-US-Neural2-D',
    'en-US-Neural2-E',
    'en-US-Neural2-F',
    'en-US-Neural2-G',
    'en-US-Neural2-H',
    'en-US-Neural2-I',
    'en-US-Neural2-J',
    'en-US-News-K',
    'en-US-News-L',
    'en-US-News-M',
    'en-US-News-N',
    'en-US-Standard-A',
    'en-US-Standard-B',
    'en-US-Standard-C',
    'en-US-Standard-D',
    'en-US-Standard-E',
    'en-US-Standard-F',
    'en-US-Standard-G',
    'en-US-Standard-H',
    'en-US-Standard-I',
    'en-US-Standard-J',
    'en-US-Studio-M',
    'en-US-Studio-O',
    'en-US-Wavenet-A',
    'en-US-Wavenet-B',
    'en-US-Wavenet-C',
    'en-US-Wavenet-D',
    'en-US-Wavenet-E',
    'en-US-Wavenet-F',
    'en-US-Wavenet-G',
    'en-US-Wavenet-H',
    'en-US-Wavenet-I',
    'en-US-Wavenet-J',
  ],
  BRITISH: [
    'en-GB-Neural2-A',
    'en-GB-Neural2-B',
    'en-GB-Neural2-C',
    'en-GB-Neural2-D',
    'en-GB-Neural2-E',
    'en-GB-Neural2-F',
    'en-GB-News-H',
    'en-GB-News-I',
    'en-GB-News-J',
    'en-GB-News-K',
    'en-GB-News-L',
    'en-GB-News-M',
    'en-GB-Standard-A',
    'en-GB-Standard-B',
    'en-GB-Standard-C',
    'en-GB-Standard-D',
    'en-GB-Standard-E',
    'en-GB-Standard-F',
    'en-GB-Wavenet-A',
    'en-GB-Wavenet-B',
    'en-GB-Wavenet-C',
    'en-GB-Wavenet-D',
    'en-GB-Wavenet-E',
    'en-GB-Wavenet-F',
  ],
  FRENCH: [
    'fr-FR-Neural2-A',
    'fr-FR-Neural2-B',
    'fr-FR-Neural2-C',
    'fr-FR-Neural2-D',
    'fr-FR-Neural2-E',
    'fr-FR-Standard-A',
    'fr-FR-Standard-B',
    'fr-FR-Standard-C',
    'fr-FR-Standard-D',
    'fr-FR-Standard-E',
    'fr-FR-Wavenet-A',
    'fr-FR-Wavenet-B',
    'fr-FR-Wavenet-C',
    'fr-FR-Wavenet-D',
    'fr-FR-Wavenet-E',
  ],
  GERMAN: [
    'de-DE-Neural2-D',
    'de-DE-Neural2-F',
    'de-DE-Standard-A',
    'de-DE-Standard-B',
    'de-DE-Standard-C',
    'de-DE-Standard-D',
    'de-DE-Standard-E',
    'de-DE-Standard-F',
    'de-DE-Wavenet-A',
    'de-DE-Wavenet-B',
    'de-DE-Wavenet-C',
    'de-DE-Wavenet-D',
    'de-DE-Wavenet-E',
    'de-DE-Wavenet-F',
  ],
  PORTUGUESE: ['pt-PT-Standard-A', 'pt-PT-Standard-B', 'pt-PT-Standard-C', 'pt-PT-Standard-D', 'pt-PT-Wavenet-A', 'pt-PT-Wavenet-B', 'pt-PT-Wavenet-C', 'pt-PT-Wavenet-D'],
  ITALIAN: [
    'it-IT-Neural2-A',
    'it-IT-Neural2-C',
    'it-IT-Standard-A',
    'it-IT-Standard-B',
    'it-IT-Standard-C',
    'it-IT-Standard-D',
    'it-IT-Wavenet-A',
    'it-IT-Wavenet-B',
    'it-IT-Wavenet-C',
    'it-IT-Wavenet-D',
  ],
  ROMANIAN: ['ro-RO-Standard-A', 'ro-RO-Wavenet-A'],
  NORWEGIAN: [
    'nb-NO-Standard-A',
    'nb-NO-Standard-B',
    'nb-NO-Standard-C',
    'nb-NO-Standard-D',
    'nb-NO-Standard-E',
    'nb-NO-Wavenet-A',
    'nb-NO-Wavenet-B',
    'nb-NO-Wavenet-C',
    'nb-NO-Wavenet-D',
    'nb-NO-Wavenet-E',
  ],
};

export const AMAZON_VOICES: LanguageMap<{[key: string]: {neural: boolean; standard: boolean}}> = {
  SPANISH: {
    Conchita: {neural: false, standard: true},
    Lucia: {neural: true, standard: true},
    Enrique: {neural: false, standard: true},
    Sergio: {neural: true, standard: false},
  },
  MEXICAN: {
    Mia: {neural: true, standard: true},
    Andres: {neural: true, standard: false},
  },
  CATALAN: {
    Arlet: {neural: true, standard: false},
  },
  ENGLISH: {
    Ivy: {neural: true, standard: true},
    Joanna: {neural: true, standard: true},
    Kendra: {neural: true, standard: true},
    Kimberly: {neural: true, standard: true},
    Salli: {neural: true, standard: true},
    Joey: {neural: true, standard: true},
    Justin: {neural: true, standard: true},
    Kevin: {neural: true, standard: false},
    Matthew: {neural: true, standard: true},
    Ruth: {neural: true, standard: false},
    Stephen: {neural: true, standard: false},
  },
  BRITISH: {
    Amy: {neural: true, standard: true},
    Emma: {neural: true, standard: true},
    Brian: {neural: true, standard: true},
    Arthur: {neural: true, standard: false},
  },
  FRENCH: {
    Celine: {neural: false, standard: true},
    Lea: {neural: true, standard: true},
    Mathieu: {neural: false, standard: true},
    Remi: {neural: true, standard: false},
  },
  GERMAN: {
    Marlene: {neural: false, standard: true},
    Vicki: {neural: true, standard: true},
    Daniel: {neural: true, standard: false},
    Hans: {neural: false, standard: true},
  },
  PORTUGUESE: {
    Ines: {neural: true, standard: true},
    Cristiano: {neural: false, standard: true},
  },
  ITALIAN: {
    Carla: {neural: false, standard: true},
    Bianca: {neural: true, standard: true},
    Giorgio: {neural: false, standard: true},
    Adriano: {neural: true, standard: false},
  },
  ROMANIAN: {
    Carmen: {neural: false, standard: true},
  },
  NORWEGIAN: {
    Liv: {neural: false, standard: true},
    Ida: {neural: true, standard: false},
  },
};
