import {AuthGuard} from './firestore/auth.guard';
import {UserResolver} from '../portal/auth/user/user.resolver';
import {UserService} from './firestore/user.service';
import {AuthService} from './firestore/auth.service';
import {ModuleWithProviders, NgModule, Optional, SkipSelf, ErrorHandler, Injectable, NgZone} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule, NbDummyAuthStrategy} from '@nebular/auth';
import {NbSecurityModule, NbRoleProvider} from '@nebular/security';
import {NbToastrService} from '@nebular/theme';
import {from, Observable, of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService} from './utils';
import {WeekService} from './utils';

import {UserData} from './data/users';
import {MockUserService} from './mock/users.service';
import {ClientService} from './firestore/clients.service';

import {ElectricityService} from './mock/electricity.service';
import {SmartTableService} from './mock/smart-table.service';
import {OrdersChartService} from './mock/orders-chart.service';
import {ProfitChartService} from './mock/profit-chart.service';
import {TrafficListService} from './mock/traffic-list.service';
import {EarningService} from './mock/earning.service';
import {OrdersProfitChartService} from './mock/orders-profit-chart.service';
import {TrafficBarService} from './mock/traffic-bar.service';
import {ProfitBarAnimationChartService} from './mock/profit-bar-animation-chart.service';
import {TemperatureHumidityService} from './mock/temperature-humidity.service';
import {SolarService} from './mock/solar.service';
import {TrafficChartService} from './mock/traffic-chart.service';
import {StatsBarService} from './mock/stats-bar.service';
import {CountryOrderService} from './mock/country-order.service';
import {StatsProgressBarService} from './mock/stats-progress-bar.service';
import {VisitorsAnalyticsService} from './mock/visitors-analytics.service';
import {SecurityCamerasService} from './mock/security-cameras.service';

import {ElectricityData} from './data/electricity';
import {SmartTableData} from './data/smart-table';
import {OrdersChartData} from './data/orders-chart';
import {ProfitChartData} from './data/profit-chart';
import {TrafficListData} from './data/traffic-list';
import {EarningData} from './data/earning';
import {OrdersProfitChartData} from './data/orders-profit-chart';
import {TrafficBarData} from './data/traffic-bar';
import {ProfitBarAnimationChartData} from './data/profit-bar-animation-chart';
import {TemperatureHumidityData} from './data/temperature-humidity';
import {SolarData} from './data/solar';
import {TrafficChartData} from './data/traffic-chart';
import {StatsBarData} from './data/stats-bar';
import {CountryOrderData} from './data/country-order';
import {StatsProgressBarData} from './data/stats-progress-bar';
import {VisitorsAnalyticsData} from './data/visitors-analytics';
import {SecurityCamerasData} from './data/security-cameras';

import {MockDataModule} from './mock/mock-data.module';
import {FirestoreDataModule} from './firestore/firestore-data.module';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS} from '@angular/common/http';
import {getAuth} from '@angular/fire/auth';

@Injectable()
export class ErrorService implements ErrorHandler {
  constructor(private toast: NbToastrService, private zone: NgZone) {}

  handleError(error: any) {
    let message = 'Undefined';
    // Check if it's an Error object
    if (error instanceof Error) {
      message = error.message; // get the error message
      console.log(error);
    }
    this.zone.run(() =>
      this.toast.danger('Application Error', message, {
        duration: 15000,
        icon: 'alert-circle-outline',
      })
    );
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator

    if (req.url.includes('translation.googleapis')) {
      return next.handle(req);
    }
    // if (req.url.includes('europe-west3-bookline')) {
    //   return next.handle(req);
    // }

    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const auth = getAuth(); // Obtener la instancia de Auth
    const currentUser = auth.currentUser; // Obtener el usuario actual

    if (currentUser) {
      const token = await currentUser.getIdToken(); // Obtener el token del usuario

      const authReq = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });

      return next.handle(authReq).toPromise();
    } else {
      return next.handle(req).toPromise(); // Si no hay usuario autenticado
    }
  }
}

const DATA_SERVICES = [
  {provide: UserData, useClass: MockUserService},
  {provide: ClientService, useClass: ClientService},
  {provide: ElectricityData, useClass: ElectricityService},
  {provide: SmartTableData, useClass: SmartTableService},
  {provide: OrdersChartData, useClass: OrdersChartService},
  {provide: ProfitChartData, useClass: ProfitChartService},
  {provide: TrafficListData, useClass: TrafficListService},
  {provide: EarningData, useClass: EarningService},
  {provide: OrdersProfitChartData, useClass: OrdersProfitChartService},
  {provide: TrafficBarData, useClass: TrafficBarService},
  {
    provide: ProfitBarAnimationChartData,
    useClass: ProfitBarAnimationChartService,
  },
  {provide: TemperatureHumidityData, useClass: TemperatureHumidityService},
  {provide: SolarData, useClass: SolarService},
  {provide: TrafficChartData, useClass: TrafficChartService},
  {provide: StatsBarData, useClass: StatsBarService},
  {provide: CountryOrderData, useClass: CountryOrderService},
  {provide: StatsProgressBarData, useClass: StatsProgressBarService},
  {provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService},
  {provide: SecurityCamerasData, useClass: SecurityCamerasService},
  {provide: ErrorHandler, useClass: ErrorService},
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...FirestoreDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
    ],
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  WeekService,
  AuthService,
  UserService,
  UserResolver,
  AuthGuard,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return <ModuleWithProviders<CoreModule>>{
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
