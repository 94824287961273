<form [formGroup]="form">
  <div class="form-group row">
    <!-- PROVIDER -->
    <div [ngClass]="displayVertical ? 'form-group row' : 'col-3'">
      <label *ngIf="displayLabels" class="label col-sm-3 col-form-label">Provider</label>
      <div [ngClass]="displayLabels ? 'col-sm-9' : 'col-sm-12'">
        <nb-select formControlName="provider" fullWidth placeholder="Provider" (selectedChange)="providerChange()">
          <nb-option *ngFor="let available_provider of availableProvidersForLanguage" [value]="available_provider">{{ available_provider }}</nb-option>
        </nb-select>
      </div>
    </div>
    <!-- VOICE TITLE -->
    <div [ngClass]="displayVertical ? 'form-group row' : 'col-3'">
      <label *ngIf="displayLabels" class="label col-sm-3 col-form-label">Voice</label>
      <div [ngClass]="displayLabels ? 'col-sm-9' : 'col-sm-12'">
        <nb-select formControlName="voice_title" fullWidth placeholder="Voice Title">
          <nb-option *ngFor="let available_voice of availableVoicesForProviderAndLanguage" [value]="available_voice">{{ available_voice }}</nb-option>
        </nb-select>
      </div>
    </div>
    <!-- VOICE SPEED -->
    <div [ngClass]="displayVertical ? 'form-group row' : 'col-3'" *ngIf="!isElevenlabs">
      <label *ngIf="displayLabels" class="label col-sm-3 col-form-label">Speed</label>
      <div [ngClass]="displayLabels ? 'col-sm-9' : 'col-sm-12'">
        <input type="number" min="0.2" max="2.0" step="0.1" nbInput fullWidth placeholder="1.2" formControlName="voice_speed" />
      </div>
    </div>
    <!-- VOICE VOLUME -->
    <div [ngClass]="displayVertical ? 'form-group row' : 'col-3'" *ngIf="!isElevenlabs">
      <label *ngIf="displayLabels" class="label col-sm-3 col-form-label">Volume</label>
      <div [ngClass]="displayLabels ? 'col-sm-9' : 'col-sm-12'">
        <nb-select formControlName="voice_volume" fullWidth placeholder="Voice Volume">
          <nb-option *ngFor="let available_volume of volumeOptions" [value]="available_volume">{{ available_volume }}</nb-option>
        </nb-select>
      </div>
    </div>
  </div>
</form>
