import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {NbDialogRef, NbPopoverDirective} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {TranslateService} from '../../../../@core/api/translate.service';
// import {check} from 'ssml-check';
// const ssmlCheck = require('ssml-check');
import * as ssmlCheck from 'ssml-check-core';

@Component({
  selector: 'ngx-language-selector-dialog',
  templateUrl: 'language-selector-dialog.component.html',
  styleUrls: ['language-selector-dialog.component.scss'],
})
export class LanguageSelectorDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() supported_languages: string[];
  @Input() placeholdersData?: any;
  @Input() value: string;
  @Input() skip_validation: boolean;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  constructor(protected ref: NbDialogRef<LanguageSelectorDialogComponent>, private fb: UntypedFormBuilder, private toastrService: NbToastrService, private ts: TranslateService) {}

  languageForm: UntypedFormGroup;
  isValid: boolean = true;
  ssmlMap: any = {
    break: '<break time="1s" />',
    volume: '<prosody volume="x-loud">silent, x-soft, soft, medium, loud, x-loud</prosody>',
    rate: '<prosody rate="default"> x-slow, slow, medium, fast, x-fast, or default</prosody>',
    'lang-aws': '<lang xml:lang="es-ES">Texto en español</lang>',
    'lang-google': '<voice language="es_ES" gender="female">Texto en español</voice>',
    phoneme: '<phoneme alphabet="ipa" ph="pɪˈkɑːn">pecan</phoneme>',
    emphasis: '<emphasis level="strong">Important</emphasis>',
    sayAs: '<say-as interpret-as="spell-out">hello</say-as>',
    audio: '<audio src="https://example.com/sound.mp3" />',
    sub: '<sub alias="World Wide Web Consortium">W3C</sub>',
    user: '$USER_NAME',
    phone: '$USER_PHONE',
    greeting: '$GREETING',
  };

  ngOnInit() {
    console.log('skip validation>>>', this.skip_validation);

    this.languageForm = this.fb.group({
      Languages: this.fb.array(this.supported_languages.map((lang) => this.newLanguage(lang))),
    });

    // Prepopulate with existing data, if any provided
    if (this.value) {
      this.setJSON(this.value);
    }
  }

  getPlaceholder(lang: string) {
    if (this.placeholdersData) {
      const jsonData = JSON.parse(this.placeholdersData);

      if (jsonData && jsonData[lang]) {
        return jsonData[lang];
      }
    }
    return '';
  }

  getLanguages() {
    return this.languageForm.get('Languages') as UntypedFormArray;
  }

  /** https://flagicons.lipis.dev/ */
  private languageFlagIcon(languageCode: string): string {
    switch (languageCode) {
      case 'ca-ES':
        return 'es-ct';
      case 'en-US':
        return 'us';
      case 'en-GB':
        return 'gb';
      case 'fr-FR':
        return 'fr';
      case 'de-DE':
        return 'de';
      case 'es-MX':
        return 'mx';
      case 'es-UY':
        return 'uy';
      case 'es-PY':
        return 'py';
      case 'es-CO':
        return 'co';
      case 'es-ES':
        return 'es';
      case 'it-IT':
        return 'it';
      case 'pt-PT':
        return 'pt';
      case 'ar-SA':
        return 'sa';
      case 'tr-TR':
        return 'tr';
      case 'ro-RO':
        return 'ro';
      case 'nb-NO':
        return 'no';
      default:
        return 'es';
    }
  }

  newLanguage(languageCode: string) {
    return this.fb.group({
      icon: [this.languageFlagIcon(languageCode)],
      languageCode: [languageCode],
      text: ['', Validators.required],
    });
  }

  async checkSsml(obj) {
    if (obj.text.includes('<voice language')) {
      return false;
    }
    const validationResult = await ssmlCheck.check('<speak>' + obj.text + '</speak>');

    if (validationResult && !this.skip_validation) {
      this.toastrService.warning(JSON.stringify(validationResult, null, 2), `Error in lang "${obj?.languageCode}"`, {duration: 20000});
      console.error(`Error in lang "${obj?.languageCode}":`);
      console.error(validationResult);
      return true;
    }
    return false;
  }

  async getJSON() {
    const jsonValues = {};
    this.isValid = true;

    for (const x of this.languageForm.value.Languages) {
      if (await this.checkSsml(x)) {
        this.isValid = false;
      }
      jsonValues[x.languageCode] = x.text;
    }

    if ('es-MX' in jsonValues) jsonValues['es-ES'] = jsonValues['es-MX']; // FIXME: when editing json for Mexican, copy them in spanish
    if ('es-UY' in jsonValues) jsonValues['es-ES'] = jsonValues['es-UY']; // FIXME: when editing json for Uruguayan, copy them in spanish
    if ('es-PY' in jsonValues) jsonValues['es-ES'] = jsonValues['es-PY']; // FIXME: when editing json for PARAGUAYAN, copy them in spanish
    if ('es-CO' in jsonValues) jsonValues['es-ES'] = jsonValues['es-CO']; // FIXME: when editing json for Colombian, copy them in spanish
    if ('en-GB' in jsonValues) jsonValues['en-US'] = jsonValues['en-GB']; // FIXME: when editing json for British, copy them in english
    return JSON.stringify(jsonValues);
  }
  decodeHtmlEntities(input) {
    const entities = {
      amp: '&',
      apos: "'",
      lt: '<',
      gt: '>',
      quot: '"',
      '#39': "'",
      '#34': '"',
    };

    return input.replace(/&([^;]+);/g, function (match, entity) {
      return entities[entity] || match;
    });
  }

  async translateText(index: number) {
    const formArray = this.getLanguages();
    const currentLang = formArray.at(index);
    const textToTranslate = currentLang.get('text').value;

    // Expresión regular para capturar variables en formato $VARIABLE y URLs
    const variableRegex = /\$[A-Z_]+/g;
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Para URLs que empiezan con http, https o www.

    let variableMap = new Map<string, string>();
    let urlMap = new Map<string, string>();
    let counter = 1; // Contador para los placeholders numéricos

    // Proteger variables en formato $VARIABLE
    let protectedText = textToTranslate.replace(variableRegex, (match) => {
      const placeholder = `<span class="notranslate">$${counter}</span>`;
      variableMap.set(placeholder, match);
      counter++;
      return placeholder;
    });

    // Proteger URLs
    protectedText = protectedText.replace(urlRegex, (match) => {
      const placeholder = `<span class="notranslate">URL_${counter}</span>`;
      urlMap.set(placeholder, match);
      counter++;
      return placeholder;
    });

    try {
      for (let i = 0; i < formArray.length; i++) {
        if (i !== index) {
          const targetField = formArray.at(i).get('text');
          if (targetField.value === '' || targetField.value === undefined) {
            const targetLangCode = formArray.at(i).get('languageCode').value;
            const response = await this.ts.translateText(protectedText, targetLangCode).toPromise();
            let translatedText = this.decodeHtmlEntities(response.data.translations[0].translatedText);

            // Restaurar las variables protegidas
            translatedText = translatedText.replace(/<span class="notranslate">\$?\d+<\/span>/g, (placeholder) => variableMap.get(placeholder));

            // Restaurar URLs protegidas
            translatedText = translatedText.replace(/<span class="notranslate">URL_\d+<\/span>/g, (placeholder) => urlMap.get(placeholder));

            targetField.setValue(translatedText);
          }
        }
      }
    } catch (error) {
      this.toastrService.show('Translation Error', error.message, {status: 'danger'});
    }
  }

  setJSON(value) {
    console.log('VALUE EN JSON INPUT', this.value);

    const v = JSON.parse(value);
    for (const lang of this.getLanguages().controls) {
      lang.get('text').setValue(v[lang.get('languageCode').value]);
    }
  }

  copySsml(key) {
    navigator.clipboard.writeText(this.ssmlMap[key]);
    const tooltip = document.getElementById(`custom-tooltip-${key}`);
    if (tooltip) {
      tooltip.style.display = 'inline';
    }

    setTimeout(() => {
      if (tooltip) {
        tooltip.style.display = 'none';
      }
      if (this.popover) {
        this.popover.hide();
      }
    }, 1000);
  }

  async save() {
    if (!this.languageForm.valid) {
      this.toastrService.show('Error', 'All languages are required', {status: 'danger'});
      return false;
    }

    const data = await this.getJSON();
    if (this.isValid) {
      this.ref.close(data);
    }
  }
  open() {
    this.popover.show();
  }
  close() {
    this.popover.hide();
  }

  dismiss() {
    this.ref.close(null);
  }

  clear() {
    this.ref.close('');
  }

  ngOnDestroy() {}
}
