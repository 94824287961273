<nb-card style="border: none" [nbSpinner]="uploadLoading" class="imageContainer" [formGroup]="form">
  <div>
    <nb-form-field style="width: 100%; display: inline-flex; margin-right: 5px; margin-bottom: 10px">
      <nb-icon nbPrefix icon="file-image" pack="fas"></nb-icon>
      <input type="text" nbInput fullWidth [formControlName]="controlName" placeholder="" />
    </nb-form-field>
    <input class="uploader" type="file" (change)="handleUploadFile($event)" accept="image/*" placeholder="Select your image" />
  </div>
  <div style="display: flex; margin: 10px 0">
    <div id="dropzone" class="dragAndDrop" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
      <div class="dropboxTitle">Drag & drop the file here</div>
    </div>
    <div *ngIf="getURL() !== ''" class="linkDiv">
      <img style="max-width: 100%; padding: 20px" [src]="getURL()" crossorigin="anonymous" alt="img" />
    </div>
  </div>
</nb-card>

<!-- <div [formGroup]="form" class="uploaderDiv"></div>
<div class="dropboxDivider">OR</div>
<div class="dropboxDivider">OR</div>
<input fullWidth nbInput [formControlName]="controlName" type="text" /> -->
