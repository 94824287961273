import {Injectable} from '@angular/core';
import {of as observableOf, Observable} from 'rxjs';
import {PeriodsService} from './periods.service';
import {OutlineData, VisitorsAnalyticsData} from '../data/visitors-analytics';

@Injectable()
export class VisitorsAnalyticsService extends VisitorsAnalyticsData {
  constructor(private periodService: PeriodsService) {
    super();
  }

  private pieChartValue = 75;
  private innerLinePoints: number[] = [
    94, 188, 225, 244, 253, 254, 249, 235, 208, 173, 141, 118, 105, 97, 94, 96, 104, 121, 147, 183, 224, 265, 302, 333, 358, 375, 388, 395, 400, 400, 397, 390, 377, 360, 338, 310, 278, 241, 204, 166,
    130, 98, 71, 49, 32, 20, 13, 9,
  ];

  getInnerLineChartData(): Observable<number[]> {
    return observableOf(this.innerLinePoints);
  }

  getPieChartData(): Observable<number> {
    return observableOf(this.pieChartValue);
  }
}
