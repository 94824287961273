import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {ArrayShift, Schedule, Shift} from '../../../@core/data/client.model';

@Component({
  selector: 'ngx-schedule-dialog',
  templateUrl: 'schedule-dialog.component.html',
  styleUrls: ['schedule-dialog.component.scss'],
})
export class ScheduleDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() schedule: Schedule;
  @Input() schedule_type: string;
  @Input() clientSchedulesMap: any;
  constructor(protected ref: NbDialogRef<ScheduleDialogComponent>, private fb: UntypedFormBuilder, private toastrService: NbToastrService) {}

  pattern = '([01]?[0-9]|2[0123]):([012345][0-9])';
  scheduleForm: UntypedFormGroup;
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  schedulesKeys = [];
  selectedScheduleToCopy: string = '';

  ngOnInit() {
    console.log('clientSchedulesMap', this.clientSchedulesMap);
    if (this.clientSchedulesMap) {
      this.schedulesKeys = Object.entries(this.clientSchedulesMap)
        .filter(([key, value]: [string, any]) => {
          if (value === null) return false;
          if (Object.keys(value?.days).length === 0) return false;
          return true;
        })
        .map((pair) => pair[0]);
    }
    const scheduleDict = {};
    this.days.forEach((day) => {
      scheduleDict[day] = this.fb.group({
        Open: [false, Validators.required],
        Slots: this.fb.array([this.fb.group({from: ['', Validators.pattern(this.pattern)], until: ['', Validators.pattern(this.pattern)]})]),
      });
    });
    this.scheduleForm = this.fb.group(scheduleDict);

    // Prepopulate with existing data
    if (this.schedule && this.schedule.days) {
      this.days.forEach((day) => {
        if (this.schedule.days[day]) {
          this.schedule.days[day].shifts.forEach((element, it) => {
            if (it > 0) {
              (this.scheduleForm.get(day).get('Slots') as UntypedFormArray).push(this.fb.group({from: ['', Validators.pattern(this.pattern)], until: ['', Validators.pattern(this.pattern)]}));
            }
            if (element.start_time !== '' || element.end_time !== '') {
              this.scheduleForm.get(day).get('Open').setValue(true);
            }
            (this.scheduleForm.get(day).get('Slots') as UntypedFormArray).at(it).get('from').setValue(element.start_time);
            (this.scheduleForm.get(day).get('Slots') as UntypedFormArray).at(it).get('until').setValue(element.end_time);
          });
        }
      });
    }
  }

  applyToAll(dayToCopy) {
    const dayScheduleToCopy = this.scheduleForm.get(dayToCopy);
    const isOpen = dayScheduleToCopy.get('Open').value;

    this.days.forEach((day) => {
      this.scheduleForm.get(day).get('Open').setValue(isOpen);
      const slotsUtil = dayScheduleToCopy.get('Slots').value;

      const targetSlots = this.scheduleForm.get(day).get('Slots') as UntypedFormArray;

      targetSlots.clear(); // Clear existing slots before copying

      slotsUtil.forEach((slot) => {
        const newSlot = this.fb.group({
          from: [slot.from],
          until: [slot.until],
        });

        targetSlots.push(newSlot);
      });
    });
  }

  copySchedule() {
    console.log('COPY', this.selectedScheduleToCopy);

    const schUtil = this.clientSchedulesMap[this.selectedScheduleToCopy];
    if (schUtil) {
      console.log('schUtil', schUtil);

      this.days.forEach((day) => {
        const isOpen: boolean = schUtil?.days[day] ? true : false;

        this.scheduleForm.get(day).get('Open').setValue(isOpen);

        const slotsUtil = schUtil?.days[day]?.shifts;
        console.log('slotsUtil', slotsUtil);

        const targetSlots = this.scheduleForm.get(day).get('Slots') as UntypedFormArray;

        targetSlots.clear(); // Clear existing slots before copying
        if (slotsUtil) {
          slotsUtil.forEach((slot) => {
            const newSlot = this.fb.group({
              from: [slot.start_time],
              until: [slot.end_time],
            });

            targetSlots.push(newSlot);
          });
        }
      });
    }
  }

  getOpen(day) {
    return this.scheduleForm.get(day).get('Open');
  }

  getSlots(day) {
    return this.scheduleForm.get(day).get('Slots') as UntypedFormArray;
  }

  getFrom(day, index) {
    return this.getSlots(day).at(index).get('from');
  }

  getUntil(day, index) {
    return this.getSlots(day).at(index).get('until');
  }

  addSlot(day) {
    this.getSlots(day).push(this.fb.group({from: ['', Validators.pattern(this.pattern)], until: ['', Validators.pattern(this.pattern)]}));
  }

  deleteSlot(day, index) {
    this.getSlots(day).removeAt(index);
  }

  get unsubmittable() {
    // Check if at least one day is open
    let oneDayOpen = false;
    this.days.forEach((day) => {
      oneDayOpen += this.getOpen(day).value;
    });
    if (!oneDayOpen) {
      return true;
    }
    // Check if form is valid
    if (this.scheduleForm.invalid) {
      return true;
    }
    return false;
  }

  save() {
    if (this.unsubmittable) {
      return false;
    }
    const schedule: Schedule = {days: {}, exceptions: [], always_enabled: null};
    let all_shifts_valid = true;
    this.days.forEach((day) => {
      schedule.days[day] = {shifts: []};
      if (this.scheduleForm.get(day).get('Open').value) {
        (this.scheduleForm.get(day).get('Slots') as UntypedFormArray).controls.forEach((slot) => {
          // Check shift value validity
          const [hh_start, mm_start] = slot.get('from').value.split(':');
          const [hh_end, mm_end] = slot.get('until').value.split(':');
          // Case 0 - number parsing went wrong
          if (isNaN(+hh_start) || isNaN(+mm_start) || isNaN(+hh_end) || isNaN(+mm_end)) {
            this.toastrService.show('Invalid values for ' + day + ' shift: ' + slot.get('from').value + '-' + slot.get('until').value, 'Error', {status: 'danger'});
            all_shifts_valid = false;
          }
          // If schedule is anything other than restaurant (regular), which may come from google,
          // Check that all shifts end later than they start
          if (this.schedule_type !== 'regular') {
            // Case 1 - shift ends earlier than start (by hour)
            if (Number(hh_start) > Number(hh_end)) {
              this.toastrService.show(day + ' shift ends before it starts: ' + slot.get('from').value + '>' + slot.get('until').value, 'Error', {status: 'danger'});
              all_shifts_valid = false;
              // Case 2 - shift ends earlier than start (by minute)
            } else if (Number(hh_start) == Number(hh_end)) {
              if (Number(mm_start) >= Number(mm_end)) {
                this.toastrService.show(day + ' shift must end after it starts: ' + slot.get('from').value + '>=' + slot.get('until').value, 'Error', {status: 'danger'});
                all_shifts_valid = false;
              }
            }
          }

          const shift: Shift = {start_time: slot.get('from').value, end_time: slot.get('until').value};
          schedule.days[day].shifts.push(shift);
        });
      } else {
        schedule.days[day].shifts.push({start_time: '', end_time: ''});
      }
    });
    if (!all_shifts_valid) {
      return false;
    }
    // // Change shiftsList to Shifts
    // this.days.forEach(day => {
    //   delete Object.assign(schedule.days[day], {['Shifts']: schedule.days[day]['shiftsList'] })['shiftsList'];
    // });
    // // Change days to Days
    // delete Object.assign(schedule, {['Days']: Object.assign({}, schedule.days) })['days'];
    Object.assign(schedule, {['days']: Object.assign({}, schedule.days)});
    this.ref.close(schedule);
  }

  dismiss() {
    this.ref.close(null);
  }

  ngOnDestroy() {}
}
