import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import * as keywords from './keywords.json';

@Component({
  selector: 'ngx-keyword-popup',
  templateUrl: 'keyword-popup.component.html',
  styleUrls: ['keyword-popup.component.scss'],
})
export class KeywordPopupComponent implements OnInit, OnDestroy {
  constructor(protected ref: NbDialogRef<KeywordPopupComponent>, private toastrService: NbToastrService) {}

  subjectsList: string[] = [];

  ngOnInit() {
    console.log('subkect list >>>>', Object.keys(keywords));

    this.subjectsList = Object.keys(keywords);
    this.subjectsList.splice(-1);
  }

  subjectKeyword(text: string) {
    switch (text) {
      case 'reservar':
        return 'Reservar';
      case 'cancelar':
        return 'Cancelar';
      case 'modificar':
        return 'Modificar';
      case 'hablarConRestaurante':
        return 'Hablar con el restaurante';
      case 'hablarConAdministracion':
        return 'Hablar con administración';
      case 'eventosCelebracionesGrupos':
        return 'Eventos, celebraciones y grupos';
      case 'pedidosDomicilio':
        return 'Pedidos a domicilio';
      default:
        return 'Default';
    }
  }

  save(subj) {
    this.ref.close(subj);
  }

  dismiss() {
    this.ref.close(null);
  }

  ngOnDestroy() {}
}
