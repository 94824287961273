<nb-card style="height: 465px; width: 370px">
  <nb-card-header style="display: flex; justify-content: space-between; align-items: center">Keywords</nb-card-header>
  <nb-card-body style="display: flex; flex-wrap: wrap; align-items: center">
    <button nbButton *ngFor="let subject of subjectsList" (click)="save(subject)" style="margin-left: 5px">{{ subjectKeyword(subject) }}</button>
  </nb-card-body>
  <nb-card-footer style="display: flex; justify-content: flex-end">
    <!-- <button nbButton status="primary" (click)="save()">Add</button> -->
    <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
  </nb-card-footer>
</nb-card>
