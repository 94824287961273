import {Component, Input, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {map, startWith} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {IdNameData, VenueGroup, PlatformService, Venue} from '../../../@core/api/platform.service';
import {defaultVenueGroup, profileToFormGroup, profileFromFormGroup} from './utils';
import {NewCustomerDialogComponent} from '../new-customer/new-customer-dialog.component';

@Component({
  selector: 'ngx-new-venue-group-dialog',
  templateUrl: 'new-venue-group-dialog.component.html',
  styleUrls: ['new-venue-group-dialog.component.scss'],
})
export class NewVenueGroupDialogComponent implements OnInit, OnDestroy {
  @Input() input_customer_id?: string;
  @Input() proposed_name?: string;
  @ViewChild('autoInput') autoInput;
  @ViewChildren('autoVenueInput') autoVenueInput: QueryList<any>;

  constructor(
    protected ref: NbDialogRef<NewVenueGroupDialogComponent>,
    private fb: UntypedFormBuilder,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private papi: PlatformService
  ) {}

  pattern = '([01]?[0-9]|2[0123]):([012345][0-9])';
  createMode: boolean = false;
  expertMode: boolean = false;
  submitting: boolean = false;
  showForm: boolean = false;
  venue_group: VenueGroup;
  mainForm: UntypedFormGroup;
  filteredOptions$: Observable<IdNameData[]>;
  venueFilteredOptions$: Observable<IdNameData[]>[] = [];

  papi_customer_ids_and_names: IdNameData[] = [];
  papi_customer_ids: string[] = [];

  papi_venue_ids_and_names: Venue[] = [];
  papi_venue_ids: string[] = [];

  async ngOnInit() {
    this.venue_group = defaultVenueGroup();
    this.mainForm = profileToFormGroup(this.venue_group, this.fb);

    this.papi_customer_ids_and_names = await this.papi.getAllCustomerIdsAndNames();
    this.papi_customer_ids = this.papi_customer_ids_and_names.map((cust) => cust.id);
    this.filteredOptions$ = of(this.papi_customer_ids_and_names);

    if (this.input_customer_id != null && this.papi_customer_ids.includes(this.input_customer_id)) {
      this.mainForm.get('customer_id').setValue(this.input_customer_id);
    }
    if (this.proposed_name != null && this.proposed_name.trim() !== '') {
      this.mainForm.get('name').setValue(this.proposed_name);
    }

    this.papi_venue_ids_and_names = await this.papi.getAllVenueIdsAndNames();
    this.papi_venue_ids = this.papi_venue_ids_and_names.map((cust) => cust.id);
    for (let venueNum = 0; venueNum < this.venueIdsFormArray.length; venueNum++) {
      this.venueFilteredOptions$.splice(venueNum, 0, of(this.papi_venue_ids_and_names));
    }
  }

  async save() {
    let profile: VenueGroup;
    try {
      profile = profileFromFormGroup(this.mainForm);
    } catch (error) {
      this.toastrService.danger('Message: ' + error.message, 'Invalid data.');
      return;
    }
    // TODO sanity checks
    this.submitting = true;
    let message = 'Venue group created!';
    try {
      const response = await this.papi.addVenueGroup(profile).toPromise();
      if (response.status === 201) {
        message = 'Venue group created!';
      }
    } catch (error: any) {
      if (error instanceof HttpErrorResponse) {
        this.toastrService.danger('Status: ' + error.status + ' message: ' + error.message, 'Unknown error creating venue.');
        return;
      } else {
        this.toastrService.danger(error, 'Unknown error updating venue.');
        return;
      }
    } finally {
      this.submitting = false;
    }
    this.toastrService.success(message, 'Success!');
    this.ref.close({
      success: true,
      data: profile,
    });
  }

  get customerId() {
    return this.mainForm.get('customer_id').value;
  }

  get customerVenues() {
    return this.papi_venue_ids_and_names.filter((venue) => venue.customer_id === this.customerId).length;
  }

  private filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.papi_customer_ids_and_names?.filter((optionValue) => optionValue.id.toLowerCase()?.includes(filterValue) || optionValue.name.toLowerCase()?.includes(filterValue));
  }

  private filterVenue(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.papi_venue_ids_and_names?.filter(
      (optionValue) => optionValue.customer_id == this.customerId && (optionValue.id.toLowerCase()?.includes(filterValue) || optionValue.name.toLowerCase()?.includes(filterValue))
    );
  }

  get venueIdsFormArray(): UntypedFormArray {
    return this.mainForm.get('venue_ids') as UntypedFormArray;
  }

  getFilteredOptions(value: string): Observable<any[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  getVenueFilteredOptions(value: string): Observable<any[]> {
    return of(value).pipe(map((filterString) => this.filterVenue(filterString)));
  }

  onVenueIdChange(index) {
    this.venueFilteredOptions$[index] = this.getVenueFilteredOptions(this.autoVenueInput.get(index)?.nativeElement.value);
  }

  onChange() {
    if (this.papi_customer_ids?.includes(this.autoInput?.nativeElement.value)) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
    this.filteredOptions$ = this.getFilteredOptions(this.autoInput?.nativeElement.value);
  }

  addVenue(index) {
    this.venueIdsFormArray.insert(
      index + 1,
      this.fb.group({
        id: [''],
      })
    );
    this.venueFilteredOptions$.splice(index + 1, 0, of(this.papi_venue_ids_and_names));
  }

  removeVenue(index) {
    this.venueIdsFormArray.removeAt(index);
    this.venueFilteredOptions$.splice(index, 1);
  }

  onVenueSelectionChange($event, index) {
    this.venueFilteredOptions$[index] = this.getVenueFilteredOptions($event);
  }

  onSelectionChange($event) {
    if (this.papi_customer_ids?.includes(this.autoInput?.nativeElement.value)) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
    this.filteredOptions$ = this.getFilteredOptions($event);
  }

  venue_name(index): string {
    const venue = this.papi_venue_ids_and_names.find((venue) => venue.id === this.venueIdsFormArray.at(index).get('id').value);
    if (venue) {
      return venue.name;
    } else return '';
  }

  isCustomerSet() {
    return this.showForm;
  }

  isValidCustomer() {
    return this.isCustomerSet() && this.customerVenues > 1;
  }

  createCustomer() {
    this.dialogService
      .open(NewCustomerDialogComponent, {
        context: {},
      })
      .onClose.subscribe((result) => {
        console.log(result);
        if (result.success) {
          this.papi_customer_ids.push(result.data.id);
          this.papi_customer_ids_and_names.push({id: result.data.id, name: result.data.name});
          this.mainForm.get('customer_id').setValue(result.data.id);
        }
      });
  }

  goToCustomer() {
    const customer_id = this.mainForm.get('customer_id').value;
    if (customer_id) {
      window.open('/customers/' + customer_id);
    }
  }

  dismiss() {
    this.ref.close({
      success: false,
      data: null,
    });
  }

  ngOnDestroy() {}
}
