<nb-card>
  <nb-card-header
    >{{ title }} - Integration Mode <nb-toggle (checkedChange)="onChangeModeToggle($event)" [checked]="integrationMode == 'json'">{{ integrationMode }}</nb-toggle>
    <button status="primary" ghost size="small" nbButton (click)="toggleTextareaExpanded('integration')">{{ isExpandedTextArea('integration') ? 'Shrink' : 'Expand' }}</button>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12">
        <div class="form-group row">
          <div class="col-sm-12">
            <textarea class="mb-2" type="text" nbInput fullWidth placeholder="" [(ngModel)]="integrationStringValue" id="integration"></textarea>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer style="display: flex; align-items: center; justify-content: space-between">
    <div>
      <button nbButton status="primary" (click)="save()">Save</button>
      <button nbButton ghost status="primary" (click)="dismiss()">Cancel</button>
    </div>
  </nb-card-footer>
</nb-card>
