import {Component, Input, OnInit, Output, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {ControlContainer, UntypedFormGroup, FormGroupDirective, FormArray} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {AudioGeneratorDialogComponent} from './audio-generator/audio-generator-dialog.component';
import {ProxyService} from '../../../@core/api/proxy.service';
import {Subscription} from 'rxjs';
// import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms

// @Pipe({name: 'safeResourceUrl'})
// export class SafeUrlPipe implements PipeTransform {
//   constructor(private readonly sanitizer: DomSanitizer) {}

//   public transform(url: string): SafeResourceUrl {
//     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
//   }
// }

@Component({
  selector: 'ngx-audio-input',
  styleUrls: ['./audio-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './audio-input.component.html',
})
export class AudioInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() formGroup: UntypedFormGroup;

  form: UntypedFormGroup;
  proxy_subs: Subscription;
  audioURL: string = '';
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer, public ps: ProxyService) {}

  ngOnInit() {
    console.log('FORMGROUP', this.formGroup);
    console.log('form >>', this.form);
    console.log('controlName >>', this.controlName);

    // this.proxy_subs = this.ps.getFirebaseStorage(this.form.get(this.controlName).value.replace('https://firebasestorage.googleapis.com', '')).subscribe((url) => {
    //   this.audioURL = url;
    // });

    if (this.formGroup != null) {
      this.form = this.formGroup;
    } else {
      this.form = <UntypedFormGroup>this.controlContainer.control;
    }
    // this.form.get(this.controlName).setValue(this.form.get(this.controlName).value.replace('https://firebasestorage.googleapis.com', 'https://proxy.dev.bookline.io/firebase_storage'));
  }

  getURL() {
    return this.form
      .get(this.controlName)
      .value.replace('https://firebasestorage.googleapis.com', 'https://proxy.dev.bookline.io/firebase_storage')
      .replace('https://storage.googleapis.com', 'https://proxy.dev.bookline.io/storage_google');
  }

  openJSON() {
    this.dialogService
      .open(AudioGeneratorDialogComponent, {
        context: {
          title: 'Edit Audio - ' + this.controlName,
          value: this.form.get(this.controlName).value,
        },
      })
      .onClose.subscribe((value) => {
        if (value != null) {
          this.form.get(this.controlName).setValue(value);
        }
      });
  }
}
