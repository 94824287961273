import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  constructor(private http: HttpClient) {}

  public translateText(sourceText: string, targetLanguage: string): Observable<any> {
    // const apiKey = 'AIzaSyA6CgW7T-7cK71lgjhVPdnXPAIi0Jj1zNQ';
    const apiKey = 'AIzaSyA8nPHH295Oynfk2k4M09-5PSw8BV909_I'; // Replace with your Google Translate API key

    const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

    const requestBody = {
      q: sourceText,
      target: targetLanguage,
    };

    return this.http.post(apiUrl, requestBody);
  }
}
