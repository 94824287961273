import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {NbToastrService} from '@nebular/theme';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {Customer, PlatformService} from '../../../@core/api/platform.service';

enum BackEndType {
  COVER_MANAGER = 'covermanager',
  RESTOO = 'restoo',
  THE_FORK = 'thefork',
  RESERVANDONOS = 'reservandonos',
  PRECOMPRO = 'precompro',
  REVO = 'revo',
}

enum TemplateType {
  UTILITY = 'UTILITY',
  MARKETING = 'MARKETING',
}

@Component({
  selector: 'template-chutator',
  templateUrl: 'template-chutator.component.html',
  styleUrls: ['template-chutator.component.scss'],
})
export class TemplateChutatorComponent {
  @Input() waba: string;

  templateForm: FormGroup;
  errorMessages: string[] = [];
  groupedTemplatesInWaba: any = [];
  groupedTemplatesToInstall: any[] = [];
  templatesToInstall: any[] = [];
  allGeneratedTemplates: any[] = [];
  templatesInWaba: any[] = [];
  uploadingTemplates: boolean = false;
  uploadTemplatesFlag: boolean = false;
  successfulUploads: number = 0;
  templatesRemaining: number = 0;
  gettingTemplates: boolean = false;
  selectedTemplates: string[] = [];
  templatesChecked: number = 0;

  // urlButtonUrl = 'https://grupomarinaalta.com/';
  urlButtonText: any = {
    es: 'Conócenos',
    en: 'About us',
    fr: 'À propos',
    de: 'Über Uns',
    pt_PT: 'Sobre nós',
    it: 'Chi siamo',
  };
  footerText: any = {
    es: 'Reserva gestionada por un asistente de bookline.ai',
    en: 'Reservation handled by a bookline.ai voice assistant',
    fr: 'Réservation traitée par un assistant vocal bookline.ai',
    de: 'Reservierung verwaltet durch einen bookline.ai-Assistenten',
    pt_PT: 'Reserva gerida por um assistente de voz da bookline.ai',
    it: 'Prenotazione gestita da un assistente vocale di bookline.ai',
  };

  constructor(private fb: FormBuilder, private http: HttpClient, protected ref: NbDialogRef<TemplateChutatorComponent>) {
    this.templateForm = this.fb.group({
      backend: [[BackEndType.COVER_MANAGER, BackEndType.RESTOO, BackEndType.THE_FORK, BackEndType.RESERVANDONOS, BackEndType.PRECOMPRO, BackEndType.REVO], Validators.required],
      templateType: [TemplateType.UTILITY, Validators.required],
      includeBooklineFooter: [true],
      includeReservationTermsButton: [true],
      includeImageHeader: [true],
      includeUrlButton: [true],
      urlButtonUrl: [''],
    });
  }

  getMissingTemplates() {
    if (this.templateForm.valid) {
      const formData = this.templateForm.value;

      // Obtener los valores del formulario
      const backend = formData.backend;
      const templateType = formData.templateType;
      const includeUrlButton = formData.includeUrlButton;
      const includeBooklineFooter = formData.includeBooklineFooter;
      const urlButtonUrl = formData.urlButtonUrl;

      // Generar la lista de templates
      this.allGeneratedTemplates = this.generateTemplates(backend, templateType, includeUrlButton, includeBooklineFooter, urlButtonUrl);

      const groupedTemplates = {};

      // Recorrer cada template
      this.allGeneratedTemplates?.forEach((template) => {
        const {name, language} = template;

        // Si el "name" ya existe en el objeto, agregar el lenguaje
        if (groupedTemplates[name]) {
          groupedTemplates[name].languages.push(language);
        } else {
          // Si no existe, crear un nuevo registro con el "name" y agregar el primer lenguaje
          groupedTemplates[name] = {
            name: name,
            languages: [language],
          };
        }
      });

      console.log('allGeneratedTemplates>>', this.allGeneratedTemplates);
      console.log('templates in waba>>', this.templatesInWaba);

      this.findMissingTemplates(this.templatesInWaba, this.allGeneratedTemplates);
    }
  }

  findMissingTemplates(installedTemplates: any, allGeneratedTemplates: any) {
    this.templatesToInstall = allGeneratedTemplates
      .filter((templateToInstall) => {
        return !installedTemplates.some((installedTemplate) => installedTemplate.name === templateToInstall.name && installedTemplate.languages.includes(templateToInstall.language));
      })
      .filter((template) => template !== null); // Filtrar los null, que son templates completamente instaladas

    const groupMissingTemplates = {};

    // Recorrer cada template
    this.templatesToInstall?.forEach((template) => {
      const {name, language} = template;

      // Si el "name" ya existe en el objeto, agregar el lenguaje
      if (groupMissingTemplates[name]) {
        groupMissingTemplates[name].languages.push(language);
      } else {
        // Si no existe, crear un nuevo registro con el "name" y agregar el primer lenguaje
        groupMissingTemplates[name] = {
          name: name,
          languages: [language],
        };
      }
    });

    this.groupedTemplatesToInstall = Object.values(groupMissingTemplates);
    this.selectedTemplates = Object.keys(groupMissingTemplates);
    this.gettingTemplates = false;

    console.log('this.groupedTemplatesToInstall>>', this.groupedTemplatesToInstall);
  }

  install() {
    this.uploadTemplatesFlag = true;
    this.uploadingTemplates = true;
    this.templatesChecked = this.templatesToInstall.filter((template) => this.selectedTemplates.includes(template.name)).length;
    this.templatesRemaining = this.templatesChecked;

    this.templatesToInstall.forEach((template) => {
      this.uploadTemplate(template);
    });
  }

  dynamicBackendTemplates(backend: string, templateType: string) {
    let url_card_details: string = '';
    if (backend === BackEndType.COVER_MANAGER) {
      url_card_details = 'https://www.covermanager.com/Reservs/input_card/?token={{1}}';
    } else {
      url_card_details = 'https://url.bookline.io/redirect/{{1}}';
    }

    return [
      // # reservation_pending_card_details_{backend.value}_no_duration_no_zone
      {
        name: `reservation_pending_card_details_${backend}_no_duration_no_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minutos', 'Mercato Italiano', '20/11/2022', '21h', '2']],
            },
            text: 'Está a apenas um passo de finalizar a sua reserva. Você tem {{1}} para completá-la. *Por favor, forneça os dados de pagamento acessando o link:* \n\n*Restaurante:* {{2}}\n*Data:* {{3}}\n*Hora:* {{4}}\n*Número de pessoas:* {{5}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completar reserva',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_no_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuti', 'Mercato Italiano', '20/11/2022', '21h', '2']],
            },
            text: 'Sei a solo un passo dal completare la tua prenotazione. Hai {{1}} per completarla. *Per favore, fornisci i dati di pagamento accedendo al link:* \n\n*Ristorante:* {{2}}\n*Data:* {{3}}\n*Ora:* {{4}}\n*Numero di persone:* {{5}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completa prenotazione',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'it',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_no_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuts', 'Mercato Italiano', '20/11/2022', '21h', '2']],
            },
            text: "Està a només un pas de finalitzar la seva reserva. Disposa de {{1}} per completar-la. *Si us plau, indiqui les dades de pagament accedint a l'enllaç:* \n\n*Restaurant:* {{2}}\n*Data:* {{3}}\n*Hora:* {{4}}\n*Comensals:* {{5}}",
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completar reserva',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_pending_card_details_' + backend + '_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuten', 'Mercato Italiano', '20/11/2022', '21h', '2']],
            },
            text: 'Sie sind nur noch einen Schritt vom Abschluss Ihrer Buchung entfernt. Du hast {{1}}, um es zu vollenden. *Bitte geben Sie Ihre Kartendaten ein, indem Sie auf den Link klicken, bevor die Zeit abgelaufen ist.* \n\n*Restaurant:* {{2}}\n*Datum:* {{3}}\n*Zeit:* {{4}}\n*Gäste:* {{5}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'komplette Buchung',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'de',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_no_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuts', 'Mercato Italiano', '20/11/2022', '21h', '2']],
            },
            text: 'You are just one step away from finalizing your reservation. You have {{1}} to complete it. *Please enter your credit card details by clicking on the link before the end of the time limit.* \n\n*Restaurant:* {{2}}\n*Date:* {{3}}\n*Time:* {{4}}\n*Diners:* {{5}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Complete reservation',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'en',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_no_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minutos', 'Mercato Italiano', '20/12/2022', '21h', '2']],
            },
            text: 'Está a solo un paso de finalizar su reserva. Dispone de {{1}} para completarla. *Por favor indique sus datos de tarjeta accediendo en el enlace antes de que finalice el tiempo*\n\n*Restaurante:* {{2}}\n*Fecha:* {{3}}\n*Hora:* {{4}}\n*Comensales:* {{5}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completar reserva',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'es',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_no_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 min', 'Mercato Italiano', '20/11/2022', '21h', '2']],
            },
            text: "Vous n'êtes plus qu'à une étape de la finalisation de votre réservation. Vous avez {{1}} pour le faire. *Veuillez saisir les détails de votre carte en cliquant sur le lien avant la fin du temps imparti.* \n\n*Restaurant:* {{2}}\n*Date:* {{3}}\n*Heure:* {{4}}\n*Guests:* {{5}}",
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Réservation complète',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'fr',
      },
      // # reservation_pending_card_details_{backend.value}_no_duration_zone
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minutos', 'La Versilia', '31/10/2022', '22h', '6', 'Terraço']],
            },
            text: 'Está a apenas um passo de finalizar a sua reserva. Você tem {{1}} para completá-la. *Por favor, forneça os dados de pagamento acessando o link:* \n\n*Restaurante:* {{2}}\n*Data:* {{3}}\n*Hora:* {{4}}\n*Número de pessoas:* {{5}}\n*Zona:* {{6}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completar reserva',
                type: 'URL',
                url: 'url_card_details',
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuti', 'La Versilia', '31/10/2022', '22h', '6', 'Terrazza']],
            },
            text: 'Sei a solo un passo dal completare la tua prenotazione. Hai {{1}} per completarla. *Per favore, fornisci i dati di pagamento accedendo al link:* \n\n*Ristorante:* {{2}}\n*Data:* {{3}}\n*Ora:* {{4}}\n*Numero di persone:* {{5}}\n*Zona:* {{6}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completa prenotazione',
                type: 'URL',
                url: 'url_card_details',
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'it',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuts', 'La Versilia', '31/10/2022', '22h', '6', 'Terrassa']],
            },
            text: "Està a només un pas de finalitzar la seva reserva. Disposa de {{1}} per completar-la. *Si us plau, indiqui les dades de pagament accedint a l'enllaç:* \n\n*Restaurant:* {{2}}\n*Data:* {{3}}\n*Hora:* {{4}}\n*Comensals:* {{5}}\n*Zona:* {{6}}",
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completar reserva',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'ca',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minuten', 'La Versilia', '31/10/2022', '22h', '6', 'Terrasse']],
            },
            text: 'Sie sind nur noch einen Schritt vom Abschluss Ihrer Buchung entfernt. Du hast {{1}}, um es zu vollenden. *Bitte geben Sie Ihre Kartendaten ein, indem Sie auf den Link klicken, bevor die Zeit abgelaufen ist.* \n\n*Restaurant:* {{2}}\n*Datum:* {{3}}\n*Zeit:* {{4}}\n*Gäste:* {{5}}\n*Zone:* {{6}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Komplette Buchung',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'de',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minutes', 'La Versilia', '31/10/2022', '22h', '6', 'Outdoor']],
            },
            text: 'You are just one step away from finalizing your reservation. You have {{1}} to complete it. *Please enter your credit card details by clicking on the link before the end of the time limit.* \n\n*Restaurant:* {{2}}\n*Date:* {{3}}\n*Time:* {{4}}\n*Diners:* {{5}}\n*Zone:* {{6}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Complete reservation',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'en',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minutos', 'La Versilia', '30/11/2022', '22h', '6', 'Terraza']],
            },
            text: 'Está a solo un paso de finalizar su reserva. Dispone de {{1}} para completarla. *Por favor indique sus datos de tarjeta accediendo en el enlace antes de que finalice el tiempo*\n\n*Restaurante:* {{2}}\n*Fecha:* {{3}}\n*Hora:* {{4}}\n*Comensales:* {{5}}\n*Zona:* {{6}}',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Completar reserva',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'es',
      },
      {
        name: `reservation_pending_card_details_${backend}_no_duration_zone`,
        category: templateType,
        components: [
          {
            example: {
              body_text: [['15 minutes', 'La Versilia', '30/11/2022', '22h', '6', 'terrasse']],
            },
            text: "Vous n'êtes plus qu'à une étape de la finalisation de votre réservation. Vous avez {{1}} pour le faire. *Veuillez saisir les détails de votre carte en cliquant sur le lien avant la fin du temps imparti.* \n\n*Restaurant:* {{2}}\n*Date:* {{3}}\n*Heure:* {{4}}\n*Guests:* {{5}}\n*Zone:* {{6}}",
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['https://www.covermanager.com/Reservs/input_card/?token='],
                text: 'Réservation complète',
                type: 'URL',
                url: url_card_details,
              },
            ],
            type: 'BUTTONS',
          },
        ],
        language: 'fr',
      },
    ];
  }
  generateTemplates(backendArr: string[], templateType: string, includeUrlButton: boolean, includeBooklineFooter: boolean, urlButtonUrl: string) {
    const templates:any = [
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tiet Pep', '15/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'A sua reserva foi *CANCELADA*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n\nPara qualquer dúvida adicional, ligue para {{5}}, por favor.',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tiet Pep', '15/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'La tua prenotazione è stata *CANCELLATA*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n\nPer qualsiasi ulteriore domanda, chiama il {{5}}, per favore.',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tiet Pep', '15/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'La seva reserva ha quedat *CANCEL·LADA*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n\nPer qualsevol consulta addicional, truqui al {{5}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Glockenspiel Restaurant', '13/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'Ihre Reservierung ist *STORNIERT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n\nFür alle anderen Anfragen, rufen Sie uns bitte unter an {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Uncle Bob Bakery and Grill', '15/12/2022', '14:30', '2', '+34666777888']],
            },
            text: 'Your reservation has been *CANCELLED*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n\nFor any further request please call us at {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tío Pepe', '13/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'Su reserva está *CANCELADA*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n\nPara cualquier otra consulta, por favor, llámenos al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_cancelled_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Chez Lousail', '13/12/2022', '14:30', '5', '+34666777888']],
            },
            text: 'Votre réservation est *ANNULÉE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n\nPour toute autre demande, veuillez nous appeler au {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      // # reservation_confirmation_duration_no_zone
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/10/2022', '22h', '2', '2h', '933 803 348']],
            },
            text: 'A sua reserva foi *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n*Duração:* {{5}}\n\nSe desejar modificar ou cancelar, ligue para {{6}}, por favor.',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/10/2022', '22h', '2', '2h', '933 803 348']],
            },
            text: 'La tua prenotazione è stata *CONFERMATA*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n*Durata:* {{5}}\n\nSe desideri modificare o cancellare, chiama il {{6}}, per favore.',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/10/2022', '22h', '2', '2h', '933 803 348']],
            },
            text: 'La seva reserva ha quedat *CONFIRMADA*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n*Duració:* {{5}}\n\nSi desitja modificar o cancel·lar, truqui al {{6}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/10/2022', '21h', '2', '2h', '933 803 348']],
            },
            text: 'Ihre Reservierung ist *BESTÄTIGT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n*Dauer:* {{5}}\n\nWenn Sie Änderungen oder Stornierungen vornehmen möchten, rufen Sie uns bitte unter an {{6}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/10/2022', '22h', '2', '2h', '933 803 348']],
            },
            text: 'Your reservation has been *CONFIRMED*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n*Duration:* {{5}}\n\nIf you wish to update or cancel your reservation call us at {{6}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/10/2022', '21h', '2', '2h', '933 803 348']],
            },
            text: 'Su reserva está *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n*Duración:* {{5}}\n\nSi desea modificar o cancelar, por favor, llámenos al {{6}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_confirmation_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['El Rancho', '19/19/2022', '22h', '2', '2h', '933 803 348']],
            },
            text: 'Votre réservation est *CONFIRMÉE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n*Durée:* {{5}}\n\nSi vous souhaitez modifier ou annuler, veuillez nous appeler au {{6}}.',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      // # reservation_confirmation_duration_zone
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1/2023', '23h', '4', 'Interior', '2h', '933 803 348']],
            },
            text: 'A sua reserva foi *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n*Zona:* {{5}}\n*Duração:* {{6}}\n\nSe desejar modificar ou cancelar, ligue para {{7}}, por favor.',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1/2023', '23h', '4', 'Interior', '2h', '933 803 348']],
            },
            text: 'La tua prenotazione è stata *CONFERMATA*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n*Zona:* {{5}}\n*Durata:* {{6}}\n\nSe desideri modificare o cancellare, chiama il {{7}}, per favore.',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1/2023', '23h', '4', 'Interior', '2h', '933 803 348']],
            },
            text: 'La seva reserva ha quedat *CONFIRMADA*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n*Zona:* {{5}}\n*Duració:* {{6}}\n\nSi desitja modificar o cancel·lar, truqui al {{7}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1/2022', '23h', '4', 'Innerhalb', '2h', '933 803 348']],
            },
            text: 'Ihre Reservierung ist *BESTÄTIGT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n*Zone:* {{5}}\n*Dauer:*  {{6}}\n\nWenn Sie Änderungen oder Stornierungen vornehmen möchten, rufen Sie uns bitte unter an {{7}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1(2023', '23h', '4', 'Indoor', '2h', '933 803 348']],
            },
            text: 'Your reservation has been *CONFIRMED*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n*Zone:* {{5}}\n*Duration:* {{6}}\n\nIf you wish to update or cancel your reservation call us at {{7}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1/2023', '23h', '4', 'Interior', '2h', '933 803 348']],
            },
            text: 'Su reserva está *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n*Zona:* {{5}}\n*Duración:* {{6}}\n\nSi desea modificar o cancelar, por favor, llámenos al {{7}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_confirmation_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['La Volteta', '8/1/2023', '23h', '4', "à l'intérieur", '2h', '933 803 348']],
            },
            text: 'Votre réservation est *CONFIRMÉE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n*Zone:* {{5}}\n*Durée:* {{6}}\n\nSi vous souhaitez modifier ou annuler, veuillez nous appeler au {{7}}.',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      // # reservation_confirmation_no_duration_no_zone
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 34']],
            },
            text: 'A sua reserva foi *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n\nSe desejar modificar ou cancelar, ligue para {{5}}, por favor.',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 34']],
            },
            text: 'La tua prenotazione è stata *CONFERMATA*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n\nSe desideri modificare o cancellare, chiama il {{5}}, per favore.',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 34']],
            },
            text: 'La seva reserva ha quedat *CONFIRMADA*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n\nSi desitja modificar o cancel·lar, truqui al {{5}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 348']],
            },
            text: 'Ihre Reservierung ist *BESTÄTIGT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n\nWenn Sie Änderungen oder Stornierungen vornehmen möchten, rufen Sie uns bitte unter an {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 348']],
            },
            text: 'Your reservation has been *CONFIRMED*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n\nIf you wish to update or cancel your reservation call us at {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 348']],
            },
            text: 'Su reserva está *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n\nSi desea modificar o cancelar, por favor, llámenos al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_confirmation_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Mimu', '12/12/2022', '22h', '5', '933 803 348']],
            },
            text: 'Votre réservation est *CONFIRMÉE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n\nSi vous souhaitez modifier ou annuler, veuillez nous appeler au {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      // # reservation_confirmation_no_duration_zone
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Terrassa', '931 222 924']],
            },
            text: 'A sua reserva foi *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n*Zona:* {{5}}\n\nSe desejar modificar ou cancelar, ligue para {{6}}, por favor.',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Terrassa', '931 222 924']],
            },
            text: 'La tua prenotazione è stata *CONFERMATA*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n*Zona:* {{5}}\n\nSe desideri modificare o cancellare, chiama il {{6}}, per favore.',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Terrassa', '931 222 924']],
            },
            text: 'La seva reserva ha quedat *CONFIRMADA*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n*Zona:* {{5}}\n\nSi desitja modificar o cancel·lar, truqui al {{6}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Interior', '931 222 924']],
            },
            text: 'Ihre Reservierung ist *BESTÄTIGT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n*Zone:* {{5}}\n\nWenn Sie Änderungen oder Stornierungen vornehmen möchten, rufen Sie uns bitte unter an {{6}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Terrace', '931 222 924']],
            },
            text: 'Your reservation has been *CONFIRMED*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n*Zone:* {{5}}\n\nIf you wish to update or cancel your reservation call us at {{6}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Terraza', '931 222 924']],
            },
            text: 'Su reserva está *CONFIRMADA*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n*Zona:* {{5}}\n\nSi desea modificar o cancelar, por favor, llámenos al {{6}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_confirmation_no_duration_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tampu', '2/11/2022', '21h', '4', 'Interior', '31 222 924']],
            },
            text: 'Votre réservation est *CONFIRMÉE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n*Zone:* {{5}}\n\nSi vous souhaitez modifier ou annuler, veuillez nous appeler au {{6}}.',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },

      // # reservation_pending_no_duration_no_zone
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'A sua reserva está *PENDENTE*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n\nSe desejar modificar ou cancelar, ligue para {{5}}, por favor.',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'La tua prenotazione è *IN SOSPESO*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n\nSe desideri modificare o cancellare, chiama il {{5}}, per favore.',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'La seva reserva ha quedat *PENDENT*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n\nSi desitja modificar o cancel·lar, truqui al {{5}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'Ihre Reservierung ist *NOCH NICHT BESTÄTIGT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n\nWenn Sie Änderungen oder Stornierungen vornehmen möchten, rufen Sie uns bitte unter an {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'Your reservation is *PENDING*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n\nIf you wish to update or cancel your reservation call us at {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'Su reserva está *PENDIENTE*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n\nSi desea modificar o cancelar, por favor, llámenos al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_pending_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Raza Madrid', '20/5/2023', '18h', '2', '933 803 348']],
            },
            text: 'Votre réservation est *EN ATTENTE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n\nSi vous souhaitez modifier ou annuler, veuillez nous appeler au {{5}}',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tiet Pep', '15/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'La seva reserva ha quedat *MODIFICADA*.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n\nPer qualsevol consulta addicional, truqui al {{5}}, si us plau.',
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Glockenspiel Restaurant', '13/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'Ihre Reservierung ist *GEÄNDERT*.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n\nFür alle anderen Anfragen, rufen Sie uns bitte unter an {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Uncle Bob Bakery and Grill', '15/12/2022', '14:30', '2', '+34666777888']],
            },
            text: 'Your reservation has been *MODIFIED*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n\nFor any further request please call us at {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tío Pepe', '13/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'Su reserva está *MODIFICADA*.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n\nPara cualquier otra consulta, por favor, llámenos al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Chez Lousail', '13/12/2022', '14:30', '5', '+34666777888']],
            },
            text: 'Votre réservation est *MODIFIÉE*.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n\nPour toute autre demande, veuillez nous appeler au {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Restaurante O Pescador', '13/12/2022', '14:30', '4', '+351987654321']],
            },
            text: 'Sua reserva foi *MODIFICADA*.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n\nPara qualquer outra dúvida, por favor, ligue para {{5}}',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_modified_no_duration_no_zone',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Ristorante Da Mario', '13/12/2022', '14:30', '4', '+391234567890']],
            },
            text: 'La tua prenotazione è stata *MODIFICATA*.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n\nPer qualsiasi altra domanda, per favore chiamaci al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tiet Pep', '15/12/2022', '14:30', '4', '+34666777888']],
            },
            text: "La seva reserva està *CANCEL·LADA* perquè no s'han introduït les dades.\n\n*Restaurant:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Comensals:* {{4}}\n\nPer qualsevol consulta addicional, truqui al {{5}}, si us plau.",
            type: 'BODY',
          },
        ],
        language: 'ca',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Glockenspiel Restaurant', '13/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'Ihre reservierung ist *STORNIERT* da die Daten nicht eingegeben wurden.\n\n*Restaurant:* {{1}}\n*Datum:* {{2}}\n*Zeit:* {{3}}\n*Gäste:* {{4}}\n\nFür alle anderen Anfragen, rufen Sie uns bitte unter an {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'de',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Uncle Bob Bakery and Grill', '15/12/2022', '14:30', '2', '+34666777888']],
            },
            text: 'Your reservation is *CANCELLED* because the card info has not been entered.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Time:* {{3}}\n*Diners:* {{4}}\n\nFor any further request please call us at {{5}}.',
            type: 'BODY',
          },
        ],
        language: 'en',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Tío Pepe', '13/12/2022', '14:30', '4', '+34666777888']],
            },
            text: 'Su reserva está *CANCELADA* debido a que no se han introducido los datos.\n\n*Restaurante:* {{1}}\n*Fecha:* {{2}}\n*Hora:* {{3}}\n*Comensales:* {{4}}\n\nPara cualquier otra consulta, por favor, llámenos al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'es',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Chez Lousail', '13/12/2022', '14:30', '5', '+34666777888']],
            },
            text: "Votre réservation est *ANNULÉE* car les données n'ont pas été saisies.\n\n*Restaurant:* {{1}}\n*Date:* {{2}}\n*Heure:* {{3}}\n*Guests:* {{4}}\n\nPour toute autre demande, veuillez nous appeler au {{5}}.",
            type: 'BODY',
          },
        ],
        language: 'fr',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Restaurante O Pescador', '13/12/2022', '14:30', '4', '+351987654321']],
            },
            text: 'Sua reserva está *CANCELADA* porque os dados não foram inseridos.\n\n*Restaurante:* {{1}}\n*Data:* {{2}}\n*Hora:* {{3}}\n*Número de pessoas:* {{4}}\n\nPara qualquer outra dúvida, por favor, ligue para {{5}}',
            type: 'BODY',
          },
        ],
        language: 'pt_PT',
      },
      {
        name: 'reservation_cancelled_no_prebooking_data',
        category: templateType,
        components: [
          {
            example: {
              body_text: [['Ristorante Da Mario', '13/12/2022', '14:30', '4', '+391234567890']],
            },
            text: 'La tua prenotazione è *CANCELLATA* perché i dati non sono stati inseriti.\n\n*Ristorante:* {{1}}\n*Data:* {{2}}\n*Ora:* {{3}}\n*Numero di persone:* {{4}}\n\nPer qualsiasi altra domanda, per favore chiamaci al {{5}}',
            type: 'BODY',
          },
        ],
        language: 'it',
      },
    ];

    const remindersTemplates = [
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'de',
        category: 'UTILITY',
        components: [
          {
            text: 'Wir würden uns sehr freuen, wenn Sie uns eine Bewertung hinterlassen könnten',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Bewertung schreiben',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'de',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/342950511_266081809617313_5018362623985395869_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=fwZhIt2aY2sQ7kNvgHL5qSv&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=AWYKlUHodgfdSStQDONUEpl&oh=01_Q5AaIP9fb_X59uZm2gBI-0fXFFP0mMAxPTawqyBu1ZCD6ny5&oe=670AC324',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', '13:00', '2']],
            },
            text: '*{{1}}*\n\nHallo, {{2}}.\n\nSie haben eine Reservierung bei uns *heute* um *{{3}}h* für *{{4}}* Personen.\n\nBitte bestätigen Sie Ihre Teilnahme.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Ja, natürlich',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Nein, es tut mir leid',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'en',
        category: 'UTILITY',
        components: [
          {
            text: "We'd appreciate if you could leave us a quick review",
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Write review',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'ca',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/328769260_672151704806738_2284844955133689505_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=GXjnBDffBN4Q7kNvgFwAWIH&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaINZv2ZOSjzImriZ4QEBd8YTNLFoQQQSnzfvsnZbdILcY&oe=670AA388',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd']],
            },
            text: "*{{1}}*\n   \nHola, {{2}}.\n\nHa estat un plaer atendre't avui.\n\nEns encantaria saber com ha estat la teva experiència. Podries explicar-nos-ho?",
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sí, clar',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'it',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/414534821_287037141118555_183525185910703164_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=UyXPCjJjPU4Q7kNvgGr4YIv&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIByHzlLJheFUOyZivkuaGld9mHaaQzQT7VtjfrpeBEhD&oe=670AA6C3',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd']],
            },
            text: '*{{1}}*\n\nCiao, {{2}}.\n\nHai una prenotazione con noi *oggi* alle *{{3}}* per *{{4}}* persone.\n\nPer favore, conferma la tua presenza.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sì, confermo',
                type: 'QUICK_REPLY',
              },
              {
                text: 'No, mi dispiace',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'en',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/363091839_995171891635754_170870937651931325_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=A_NrxPHVIPUQ7kNvgFsy0zs&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=AWYKlUHodgfdSStQDONUEpl&oh=01_Q5AaICDB76M61yDGDcqHIvjqASf2E_7VlZliGSMVH944FMyA&oe=670ACBFA',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', '13:00', '4']],
            },
            text: '*{{1}}*\n\nHello, {{2}}.\n\nYou have a reservation with us *today* at *{{3}}h* for *{{4}}* people.\n\nPlease confirm your attendance.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Yes, of course',
                type: 'QUICK_REPLY',
              },
              {
                text: "No, I'm sorry",
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'fr',
        category: 'UTILITY',
        components: [
          {
            text: 'Nous apprécierions vraiment si vous pouviez nous laisser un commentaire',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Écrire une critique',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'en',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/343030544_251376101145862_9196302837244679005_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=cNOvYyuBVa4Q7kNvgFYGWPH&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIO1dOfbxR7E-79XniMYV0wgIwt15xrff7hHqsVkBAOWl&oe=670AA780',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['bookline', 'clara']],
            },
            text: '*{{1}}*\n   \nHello, {{2}}.\n\nIt has been a pleasure having you today.\n\nWe would love to know how your experience has been. Could you tell us about it?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Yes, of course',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'de',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/319929666_1025401975138066_3231285254454111005_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=iPUbLnzpfl4Q7kNvgHrtVtZ&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIISDU8xR00KXPQH7nnn1R-fe3uF0gv1bwP69jqys6AEl&oe=670AA51B',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd']],
            },
            text: '*{{1}}*\n\nHallo, {{2}}.Es war mir eine Freude, Ihnen heute zu dienen.Wir würden gerne wissen, wie Ihre Erfahrung war. \n\nKönnten Sie uns das mitteilen?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Ja natürlich',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'fr',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/322272054_1375714676357123_6199329133190167259_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=2BZmiw0tXJEQ7kNvgFdcoB4&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIHVQ6EHz8LLBoEBOCqaHi18ve2fb4uT0BjffZmVmbTO_&oe=670AA3D7',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd']],
            },
            text: "*{{1}}*\n\nBonjour {{2}}.\n\nCe fut un plaisir de vous servir aujourd'hui.\n\nNous aimerions savoir comment votre expérience a été. Pourriez-vous nous dire?",
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Oui, bien sûr',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'fr',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/366885826_1937659176608634_8674046767672097897_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=iXo6PpzJ2IQQ7kNvgHaQRuV&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaINaEQxgyu2gt9vc51Gu6v5fC_zv2M5Dg18SG00bVnJzb&oe=670AB0AC',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', '13:00', '4']],
            },
            text: "*{{1}}*\n\nBonjour, {{2}}.\n\nVous avez une réservation avec nous *aujourd'hui* à *{{3}}h* pour *{{4}}* personnes.\n\nVeuillez confirmer votre présence.",
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Oui, bien sûr',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Non, je suis désolé',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'ca',
        category: 'UTILITY',
        components: [
          {
            text: "T'agrairíem molt que poguessis deixar-nos una ressenya",
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Escriure ressenya',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'es',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/360523773_323928956738546_202799453007530279_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=AXBNYfiR01YQ7kNvgHrkv9d&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIHWKgQSYX6I7CvqB14fY3TfBRBkciuPYSIvLeVLO_397&oe=670AB961',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['bookline', 'Clara']],
            },
            text: '*{{1}}*\n   \nHola, {{2}}.\n\nHa sido un placer atenderte hoy.\n\nNos encantaría saber cómo ha sido tu experiencia. ¿Podrías contárnoslo?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sí, adelante',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'it',
        category: 'UTILITY',
        components: [
          {
            text: 'Ti saremmo molto grati se potessi lasciarci una recensione.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Scrivere una recensione',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'es',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/368010532_1046029263221744_7468605886559120569_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=LnmWGibihhcQ7kNvgHvO6Ix&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=AWYKlUHodgfdSStQDONUEpl&oh=01_Q5AaID_Ut-GwcL5aI3zepJJxLBrpFIhogXn1fMNAc-vY_Xnu&oe=670AA4E9',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['Restaurante Bookline', 'Clara', '13:00', '2']],
            },
            text: '*{{1}}*\n\nHola, {{2}}.\n\nTienes una reserva con nosotros *hoy* a las *{{3}}h* para *{{4}}* personas.\n\nPor favor, confirma tu asistencia.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sí, confirmo',
                type: 'QUICK_REPLY',
              },
              {
                text: 'No, lo siento',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'pt_PT',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/322301492_434397499449944_8276260729257399389_n.jpg?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=3jcZGr0hEIwQ7kNvgGFPau-&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIBSPkfWdfiYphNYVx9yz0hdy2es_KJPZ-S_wJ3ydULd9&oe=670AB3D2',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['HOla', 'd', 'd', 'd']],
            },
            text: '*{{1}}*\n\nOlá, {{2}}.\n\nTem uma reserva conosco *hoje* as *{{3}}h* para *{{4}}* pessoas.\n\nPor favor, confirme sua presença.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sim, confirmo',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Não, desculpe',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_confirmation',
        language: 'ca',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/348533551_1681423695617463_1544340702301142921_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=ilRFljvt15UQ7kNvgFQDtYS&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIHjwRdTL6QbRMetcn7G9LFeWOspNhplEWw6xNJtGRqAf&oe=670AAAE6',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', '13:00', '4']],
            },
            text: '*{{1}}*\n\nHola, {{2}}.\n\nTens una reserva amb nosaltres *avui* a les *{{3}}h* per a *{{4}}* persones.\n\nSi us plau, confirma la teva assistència.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sí, clar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'No, ho sento',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'it',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/417318144_1381363632515824_1894289371348961683_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=BkWtmkBBKUAQ7kNvgEH3FsU&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIPuDLQJ3-4OyOGrfR1OSSbPoW1CuWbU-udhLXGR5kBzF&oe=670AB9AF',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd']],
            },
            text: "*{{1}}*\n\nCiao, {{2}}.\n\nÈ stato un piacere servirti oggi.\n\nCi piacerebbe sapere com'è stata la tua esperienza. Potresti raccontarcela?",
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sí, vai avanti',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'pt_PT',
        category: 'UTILITY',
        components: [
          {
            text: 'Ficaríamos muito gratos se você pudesse nos deixar um comentário.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Escrever análise',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_review_link_place_id',
        language: 'es',
        category: 'UTILITY',
        components: [
          {
            text: 'Te agradeceríamos mucho que pudieras dejarnos una reseña',
            type: 'BODY',
          },
          {
            buttons: [
              {
                example: ['CfVIlQ5UYB45EAI/review'],
                text: 'Escribir reseña',
                type: 'URL',
                url: 'https://search.google.com/local/writereview?placeid={{1}}',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'bookline_restaurant_post_reservation_survey',
        language: 'pt_PT',
        category: 'UTILITY',
        components: [
          {
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/328117407_989059459515551_9145019344951168870_n.jpg?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=dHfOHsKpuS8Q7kNvgGodaEV&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=A78yT0CHzsFCipFMhY0gkdO&oh=01_Q5AaIMQ_Trr5vL-ec7Zrc4TKF0MjLn8sWW6AVi8xzredhy1_&oe=670AC411',
              ],
            },
            format: 'IMAGE',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd']],
            },
            text: '*{{1}}*\n   \nOlá, {{2}}.\n\nFoi um prazer atendê-lo hoje.\n\nGostaríamos de saber como foi a experiência. Poderia nos contar?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Sim, por favor',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
    ];

    const sinLibroTemplates = [
      {
        name: 'new_reservation_details',
        language: 'es',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nueva solicitud de reserva',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['Federico Buldin', '+34 666 126 203', '12/03/2022', 'las 2', '3', 'terraza']],
            },
            text: '{{1}} ({{2}}) quiere reservar el {{3}} a {{4}} para {{5}} en {{6}}.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Aceptar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rechazar',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'en',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Restaurant Cheri', '13/05', '14:00', '4 p.']],
            },
            text: "We have good news for you! Your reservation at {{1}} on {{2}} at {{3}} for {{4}} has been successfully confirmed. Please remember to let us know if you can't make it to the restaurant.",
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'de',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Chez Voila', '13/05', '14:00', '4 Personen']],
            },
            text: 'Wir haben gute Nachrichten für Sie! Ihre Reservierung im {{1}} am {{2}} um {{3}} für {{4}} wurde erfolgreich bestätigt. Bitte denken Sie daran, uns Bescheid zu geben, wenn Sie nicht ins Restaurant kommen können.',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'fr',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Chez Voilà', '13/05', '14:00', '3 personnes']],
            },
            text: "Nous avons le regret de vous informer qu'il n'y a pas de disponibilité à {{1}} le {{2}} à {{3}} pour {{4}}. N'hésitez pas à nous contacter pour faire une autre réservation, nous vous attendons avec impatience !",
            type: 'BODY',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'de',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Neue Reservierungsanfrage',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) möchte eine Reservierung für den {{3}} um {{4}} für {{5}} um {{6}} Uhr vornehmen.\n\nWas möchten Sie bezüglich dieser Reservierung tun?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Akzeptieren',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Ablehnen',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Reservierungsdetails',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details',
        language: 'de',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Neue Reservierungsanfrage',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) möchte eine Reservierung für den {{3}} um {{4}} für {{5}} um {{6}} Uhr vornehmen.\n\nWas möchten Sie bezüglich dieser Reservierung tun?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Akzeptieren',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Ablehnen',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'es',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nueva solicitud de reserva',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['Luis Gómez', '+34657657657', '15/04', 'las 12:45', '4 personas', 'Restaurante La Masia']],
            },
            text: '{{1}} ({{2}}) quiere reservar el {{3}} a {{4}} para {{5}} en {{6}}.',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Aceptar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rechazar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Detalles de Reserva',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'de',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Casa Pepito', '13/05', '14:00', '3 Personen']],
            },
            text: 'Wir bedauern, Ihnen mitteilen zu müssen, dass im {{1}} am {{2}} um {{3}} für {{4}} kein Platz mehr frei ist. Bitte zögern Sie nicht, uns zu kontaktieren, um eine andere Reservierung zu machen, wir freuen uns auf Sie!',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'new_reservation_details',
        language: 'en',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'New reservation request',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) would like to make a reservation on {{3}} at {{4}} for {{5}} at {{6}}.\n\nWhat do you want to do about this reservation?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Accept',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Reject',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'it',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['d', 'd', 'd', 'd']],
            },
            text: '¡Abbiamo buone notizie per te! La tua prenotazione presso il {{1}} per {{2}} alle {{3}} per {{4}} è stata confermata con successo. Ti preghiamo di ricordarci se alla fine non riesci ad andare al ristorante.',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'pt_PT',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['D', 'D', 'D', 'D']],
            },
            text: 'Temos boas notícias para si! A sua reserva no {{1}} a {{2}} para {{3}} para {{4}} foi confirmada com sucesso. Lembre-se de nos avisar se não puder comparecer ao restaurante.',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'fr',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nouvelle demande de réservation',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) souhaite faire une réservation pour le {{3}} à {{4}} pour {{5}} à {{6}}.\n\nQue souhaitez-vous faire concernant cette réservation ?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Accepter',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rejeter',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Détails de la réservation',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'en',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'New reservation request',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) would like to make a reservation on {{3}} at {{4}} for {{5}} at {{6}}.\n\nWhat do you want to do about this reservation?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Accept',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Reject',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Reservation Details',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'ca',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nova sol·licitud de reserva',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) vol fer una reserva per al {{3}} a les {{4}} per a {{5}} a les {{6}}.\n\nQuè voleu fer amb aquesta reserva?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Acceptar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rebutjar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Detalls de la reserva',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details',
        language: 'it',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nuova richiesta di prenotazione',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) vuole prenotare il {{3}} da {{4}} per {{5}} a {{6}}.\n \n¿Cosa desideri fare con questa prenotazione?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Accettare',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rifiutare',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'pt_PT',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nova solicitação de reserva',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['D', 'D', 'D', 'D', 'D', 'D']],
            },
            text: '{{1}} ({{2}}) gostaria de fazer uma reserva para o dia {{3}} às {{4}} para {{5}} pessoas no {{6}}.\n\nO que deseja fazer em relação a esta reserva?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Aceitar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rejeitar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Detalhes da Reserva',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'es',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Restaurante Grillo', '15/04', 'las 15:30', '3 personas']],
            },
            text: 'Lamentamos informarle de que no hay disponibilidad en {{1}} el {{2}} a {{3}} para {{4}}. Por favor, no dude en contactarnos para realizar otra reserva. ¡Le esperamos!',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'fr',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Chez Cheri', '13/05', '12:00', '4 personnes']],
            },
            text: "Nous avons de bonnes nouvelles pour vous! Votre réservation à {{1}} le {{2}} à {{3}} pour {{4}} a été confirmée avec succès. N'oubliez pas de nous prévenir si vous ne pouvez pas vous rendre au restaurant.",
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'ca',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Casa Pepita', '13/05', '14:00', '3 persones']],
            },
            text: 'Bones notícies! La teva reserva a {{1}} el {{2}} a {{3}} per {{4}} ha estat confirmada amb èxit. Si us plau, recorda avisar-nos si finalment no pots acudir al restaurant.',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'it',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['d', 'd', 'd', 'd']],
            },
            text: "Ci dispiace comunicarle che non c'è disponibilità presso {{1}} il {{2}} alle {{3}} per {{4}}. Non esiti a contattarci per effettuare un'altra prenotazione. La aspettiamo!",
            type: 'BODY',
          },
        ],
      },
      {
        name: 'new_reservation_details',
        language: 'ca',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nova sol·licitud de reserva',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) vol fer una reserva per al {{3}} a les {{4}} per a {{5}} a les {{6}}.\n\nQuè voleu fer amb aquesta reserva?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Acceptar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rebutjar',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'ca',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Casa Pepito', '13/05', '14:00', '4 persones']],
            },
            text: 'Lamentem comunicar-li que no hi ha disponibilitat a {{1}} el {{2}} a {{3}} per {{4}}. Si us plau, no dubti en contactar-nos per realitzar una altra reserva. Li esperem!',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'pt_PT',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Restaruante taberna Albaricoque', '20-08-2024', '19:00', '4 pessoas']],
            },
            text: 'Lamentamos informar que não há disponibilidade no {{1}} a {{2}} às {{3}} para {{4}}. Por favor não hesite em contactar-nos para fazer outra reserva. Estamos à sua espera',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'new_reservation_details',
        language: 'pt_PT',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nova solicitação de reserva',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['p', 'l', 'o', 'k', 'm', 'j']],
            },
            text: '{{1}} ({{2}}) gostaria de fazer uma reserva para o dia {{3}} às {{4}} para {{5}} pessoas no {{6}}.\n\nO que deseja fazer em relação a esta reserva?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Aceitar',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rejeitar',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_rejected_detailed_no_zone',
        language: 'en',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Restaurant Pepito', '13/05', '14:00', '3 people']],
            },
            text: 'We regret to inform you that there is no availability at {{1}} on {{2}} at {{3}} for {{4}}. Please do not hesitate to contact us to make another reservation. We look forward to seeing you!',
            type: 'BODY',
          },
        ],
      },
      {
        name: 'new_reservation_details',
        language: 'fr',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nouvelle demande de réservation',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) souhaite faire une réservation pour le {{3}} à {{4}} pour {{5}} à {{6}}.\n\nQue souhaitez-vous faire concernant cette réservation ?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Accepter',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rejeter',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'new_reservation_details_comments',
        language: 'it',
        category: 'MARKETING',
        components: [
          {
            format: 'TEXT',
            text: 'Nuova richiesta di prenotazione',
            type: 'HEADER',
          },
          {
            example: {
              body_text: [['d', 'd', 'd', 'd', 'd', 'd']],
            },
            text: '{{1}} ({{2}}) desidera effettuare una prenotazione per il {{3}} alle {{4}} per {{5}} a {{6}}.\n\n¿Cosa desideri fare con questa prenotazione?',
            type: 'BODY',
          },
          {
            buttons: [
              {
                text: 'Accettare',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Rifiutare',
                type: 'QUICK_REPLY',
              },
              {
                text: 'Dettagli',
                type: 'QUICK_REPLY',
              },
            ],
            type: 'BUTTONS',
          },
        ],
      },
      {
        name: 'demo_reservation_approved_detailed_no_zone',
        language: 'es',
        category: 'UTILITY',
        components: [
          {
            example: {
              body_text: [['Restaurante Grillo', '15/04', '15:30', '3 perosonas']],
            },
            text: '¡Tenemos buenas noticias para ti! Tu reserva en {{1}} el {{2}} a {{3}} para {{4}} ha quedado confirmada con éxito. Por favor, recuerda avisarnos si finalmente no puedes acudir al restaurante.',
            type: 'BODY',
          },
        ],
      },
    ];

    backendArr.forEach((backend) => {
      const templatesUtil = this.dynamicBackendTemplates(backend, templateType);
      templates.push(...templatesUtil);
    });

    const templatesUtil = templates
      .map((template: any) => {
        // Aplicar la regla para omitir 'reservation_cancelled_no_prebooking_data' si no es COVER_MANAGER
        if (!backendArr.includes(BackEndType.COVER_MANAGER) && template.name === 'reservation_cancelled_no_prebooking_data') {
          return null; // Omitimos este template
        }

        // Agregar la cabecera de imagen si está habilitada
        if (this.templateForm.get('includeImageHeader').value) {
          template.components.push(this.createHeaderImageComponent());
        }

        // Agregar botones si no hay ningún botón presente y según las configuraciones
        if (!template.components.some((component: any) => component.type === 'BUTTONS')) {
          if (includeUrlButton) {
            template.components.push(this.createUrlButtonComponent(template.language, urlButtonUrl));
          } else if (this.templateForm.get('includeReservationTermsButton').value && !template.name.includes('cancelled')) {
            template.name += '_with_terms';
            template.components.push(this.createReservationTermsButtonComponent(template.language));
          }
        }

        // Agregar el footer si no hay ningún footer presente y está habilitado
        if (includeBooklineFooter && !template.components.some((component: any) => component.type === 'FOOTER')) {
          const buttonComponent = template.components.find((component: any) => component.type === 'BUTTONS');
          if (!buttonComponent) {
            template.components.push(this.createFooterComponent(template.language));
          } else {
            const buttonIndex = template.components.indexOf(buttonComponent);
            template.components.splice(buttonIndex, 0, this.createFooterComponent(template.language));
          }
        }

        // Devolver el template modificado
        return {
          ...template,
          category: templateType,
        };
      })
      .filter((template) => template !== null); // Filtrar los templates omitidos

    const finalTemplates: any = [...sinLibroTemplates, ...templatesUtil, ...remindersTemplates];
    return finalTemplates;
  }

  createUrlButtonComponent(language: string, urlButtonUrl: string) {
    return {
      buttons: [
        {
          text: this.urlButtonText[language],
          type: 'URL',
          url: urlButtonUrl,
        },
      ],
      type: 'BUTTONS',
    };
  }

  createFooterComponent(language: string) {
    return {
      text: this.footerText[language],
      type: 'FOOTER',
    };
  }
  createReservationTermsButtonComponent(language: string) {
    return {
      buttons: [
        {
          text: 'Terms of reservation',
          type: 'QUICK_REPLY',
        },
      ],
      type: 'BUTTONS',
    };
  }

  createHeaderImageComponent() {
    return {
      example: {
        header_handle: ['https://storage.googleapis.com/bookline-rec/whats_templates_photos/steakburger-preciados-confuego.jpeg'],
      },
      format: 'IMAGE',
      type: 'HEADER',
    };
  }

  toggleSelectAll(event: any) {
    if (event.target.checked) {
      // Seleccionar todas las plantillas
      this.selectedTemplates = this.groupedTemplatesToInstall.map((template) => template.name);
    } else {
      // Deseleccionar todas las plantillas
      this.selectedTemplates = [];
    }
  }

  isAllSelected() {
    // Devuelve true si todas las plantillas están seleccionadas
    return this.selectedTemplates.length === this.groupedTemplatesToInstall.length;
  }

  onTemplateSelect(template: any, event: any) {
    if (event.target.checked) {
      // Si se selecciona, agrega el nombre de la plantilla al array
      this.selectedTemplates.push(template.name);
    } else {
      // Si se deselecciona, remueve el nombre del array
      this.selectedTemplates = this.selectedTemplates.filter((t) => t !== template.name);
    }
  }

  uploadTemplate(template: any) {
    // const url = `https://proxy.dev.bookline.io/360d_partner/api/v2/partners/JlKl7HPA/waba_accounts/${this.waba}/waba_templates`;
    const url = "test"

    if (this.selectedTemplates?.includes(template?.name)) {
      return this.http
        .post(url, template)
        .toPromise()
        .then(
          (response: any) => {
            console.log('template uploaded>>', response);
            this.templatesRemaining--; // Resta uno al contador de templates restantes
            this.successfulUploads++; // Aumenta el contador de uploads exitosos
            if (this.templatesRemaining === 0) {
              this.uploadingTemplates = false; // Oculta el spinner cuando se terminen de subir todas
            }
          },
          (error) => {
            this.templatesRemaining--; // También resta en caso de error
            this.errorMessages.push(`⚠️ <strong>${template.name} [${template.language}]</strong>:<br>Error: ${error.message}`);
            if (this.templatesRemaining === 0) {
              this.uploadingTemplates = false; // Oculta el spinner cuando se terminen de subir todas
            }
          }
        );
    }
  }

  getTemplates() {
    this.gettingTemplates = true;
    this.groupedTemplatesInWaba = [];
    this.groupedTemplatesToInstall = [];
    this.uploadTemplatesFlag = false;
    this.errorMessages = [];
    this.templatesChecked = 0;

    if (this.waba) {
      const url = `https://proxy.dev.bookline.io/360d_partner/api/v2/partners/JlKl7HPA/waba_accounts/${this.waba}/waba_templates`;

      this.http
        .get(url)
        .toPromise()
        .then(
          (response: any) => {
            console.log('templates in waba>>', response);

            const groupedTemplates = {};

            // Recorrer cada template
            response?.waba_templates?.forEach((template) => {
              const {name, language} = template;

              // Si el "name" ya existe en el objeto, agregar el lenguaje
              if (groupedTemplates[name]) {
                groupedTemplates[name].languages.push(language);
              } else {
                // Si no existe, crear un nuevo registro con el "name" y agregar el primer lenguaje
                groupedTemplates[name] = {
                  name: name,
                  languages: [language],
                };
              }
            });
            this.templatesInWaba = Object.values(groupedTemplates).sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            });

            this.groupedTemplatesInWaba = this.templatesInWaba;
            this.getMissingTemplates();
          },
          (error) => {
            console.error(error);
            this.gettingTemplates = false;
          }
        );
    } else {
      console.log('no waba selected');
    }
  }

  dismiss() {
    this.ref.close({
      success: true,
    });
  }
}
