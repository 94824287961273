import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {Schedule as NewSchedule, Shift as NewShift} from '../../../@core/data/model-utils.model';
import {ClientService} from '../../../@core/firestore/clients.service';
import {Schedule as OldSchedule, Shift as OldShift} from '../../../@core/data/client.model';
import {ScheduleDialogComponent} from '../../../portal/restaurant-bots/schedule/schedule-dialog.component';

const OldDayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
const NewDayOptions = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

// https://stackoverflow.com/questions/52893088/forwarding-formcontrolname-to-inner-component-in-angular-with-reactive-forms
@Component({
  selector: 'ngx-schedule-editor',
  styleUrls: ['./schedule-editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  templateUrl: './schedule-editor.component.html',
})
export class ScheduleEditorComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() title?: string;

  form: UntypedFormGroup;
  watchFormGroupChanges: boolean = false;
  constructor(private dialogService: NbDialogService, public controlContainer: ControlContainer, private clientService: ClientService) {}

  ngOnInit() {
    if (this.formGroup != null) {
      this.form = this.formGroup;
      this.watchFormGroupChanges = true;
    } else {
      this.form = <UntypedFormGroup>this.controlContainer.control;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.watchFormGroupChanges) {
      for (const propertyName in changes) {
        if (propertyName == 'formGroup') {
          this.ngOnInit();
          break;
        }
      }
    }
  }

  get controlValue(): NewSchedule {
    return this.form.get(this.controlName).value;
  }

  oldToNewShiftArray(shifts: OldShift[]): NewShift[] {
    const new_shifts: NewShift[] = [];
    for (const old_shift of shifts) {
      if (old_shift == null || old_shift.start_time == '' || old_shift.end_time == '') {
        continue;
      }
      const start_hour = parseInt(old_shift.start_time.split(':')[0]);
      const start_minute = parseInt(old_shift.start_time.split(':')[1]);
      const end_hour = parseInt(old_shift.end_time.split(':')[0]);
      const end_minute = parseInt(old_shift.end_time.split(':')[1]);
      const new_shift = {
        start: {hour: start_hour, minute: start_minute, carry_over: false},
        end: {hour: end_hour, minute: end_minute, carry_over: false},
      };
      new_shifts.push(new_shift);
    }
    return new_shifts;
  }

  oldToNewSchedule(schedule: OldSchedule): NewSchedule {
    const new_schedule: NewSchedule = {monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: []};
    const old_schedule_days = schedule.days;

    for (let [index, day] of OldDayOptions.entries()) {
      let new_day = NewDayOptions[index];
      if (old_schedule_days[day] && old_schedule_days[day].shifts.length > 0) {
        new_schedule[new_day] = this.oldToNewShiftArray(old_schedule_days[day].shifts);
      }
    }

    return new_schedule;
  }

  newToOldShiftArray(shifts: NewShift[]): OldShift[] {
    if (shifts?.length > 0) {
      return shifts.map((shift) => {
        return {
          start_time: `${String(shift.start.hour).padStart(2, '0')}:${String(shift.start.minute).padStart(2, '0')}`,
          end_time: `${String(shift.end.hour).padStart(2, '0')}:${String(shift.end.minute).padStart(2, '0')}`,
        };
      });
    }
    return [];
  }

  newToOldSchedule(schedule: NewSchedule): OldSchedule {
    const old_schedule: OldSchedule = {days: {}, exceptions: []};
    for (let [index, day] of OldDayOptions.entries()) {
      let new_day = NewDayOptions[index];
      if (schedule[new_day] && schedule[new_day].length > 0) {
        // Solo revertimos si hay turnos definidos para ese día
        old_schedule.days[day] = {
          shifts: this.newToOldShiftArray(schedule[new_day]),
        };
      } else {
        old_schedule.days[day] = {
          shifts: [],
        };
      }
    }
    return old_schedule;
  }

  get ScheduleDescription(): {[k: string]: string} {
    return this.clientService.getScheduleDescriptor(this.newToOldSchedule(this.controlValue));
  }

  get isEmptySchedule(): boolean {
    const schedule = this.controlValue;
    for (let day of NewDayOptions) {
      if (schedule[day] && schedule[day].length > 0) {
        return false;
      }
    }
    return true;
  }

  openScheduleModal() {
    let title = `Add ${this.title ?? this.controlName} schedule`;

    this.dialogService
      .open(ScheduleDialogComponent, {
        context: {
          title: title,
          schedule: this.newToOldSchedule(this.controlValue),
        },
      })
      .onClose.subscribe((result) => {
        if (result) {
          this.form.get(this.controlName).setValue(this.oldToNewSchedule(result));
        }
      });
  }
}
