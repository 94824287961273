import {Component, OnInit, OnDestroy} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-schedule-dialog',
  template: ` <nb-card>
    <nb-card-header>Are you sure?</nb-card-header>
    <nb-card-footer>
      <button class="cancel" nbButton status="basic" (click)="dismiss()">Cancel</button>
      <button nbButton status="danger" (click)="save()">Confirm</button>
    </nb-card-footer>
  </nb-card>`,
  styleUrls: ['./confirm-delete.scss'],
})
export class ConfirmDeleteComponent implements OnInit, OnDestroy {
  constructor(protected ref: NbDialogRef<ConfirmDeleteComponent>) {}

  ngOnInit() {}

  save() {
    this.ref.close(true);
  }

  dismiss() {
    this.ref.close(false);
  }

  ngOnDestroy() {}
}
