<form [formGroup]="form">
  <div class="form-group row">
    <!-- PROVIDER -->
    <div [ngClass]="displayVertical ? 'form-group row' : 'col-3'">
      <label *ngIf="displayLabels" class="label col-sm-3 col-form-label">Provider</label>
      <div [ngClass]="displayLabels ? 'col-sm-9' : 'col-sm-12'">
        <nb-select formControlName="provider" fullWidth placeholder="Provider" (selectedChange)="providerChange()">
          <nb-option *ngFor="let available_provider of availableProvidersForLanguage" [value]="available_provider">{{ available_provider }}</nb-option>
        </nb-select>
      </div>
    </div>
  </div>
</form>
