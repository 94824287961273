import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ClientService} from './clients.service';

const SERVICES = [ClientService];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class FirestoreDataModule {
  static forRoot(): ModuleWithProviders<FirestoreDataModule> {
    return <ModuleWithProviders<FirestoreDataModule>>{
      ngModule: FirestoreDataModule,
      providers: [...SERVICES],
    };
  }
}
