<nb-card>
  <nb-card-header style="display: flex; justify-content: space-between; align-items: center"
    >
    <div>New customer</div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="mainForm">
      <div class="form-group">
        <!-- ID -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">ID<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <input
                type="text"
                nbInput
                fullWidth
                placeholder="ID"
                formControlName="id"
                disabled
              />
          </div>
        </div>
        <!-- Name -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Name<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <input
                type="text"
                nbInput
                fullWidth
                placeholder="Name"
                formControlName="name"
              />
          </div>
        </div>
        <!-- CRM ID -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Salesforce ID <span>(parent account)</span></label>
          <div class="col-sm-8">
            <input
                type="text"
                nbInput
                fullWidth
                placeholder="Salesforce ID"
                formControlName="crm_id"
              />
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" (click)="save()" [disabled]="submitting" [nbSpinner]="submitting">Save</button>
    <button nbButton ghost status="primary" (click)="dismiss()" [disabled]="submitting">Cancel</button>
  </nb-card-footer>
</nb-card>
