<div class="form-group row">
  <label class="label col-sm-3 col-form-label">{{ title }}</label>
  <div class="schedule-description" *ngIf="!isEmptySchedule">
    <table>
      <tbody>
        <tr *ngFor="let row of ScheduleDescription | keyvalue">
          <td class="row-day">{{ row.key | slice: 1 }}</td>
          <td class="row-shift">{{ row.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <button class="flex-sub" nbButton status="info" size="tiny" ghost (click)="openScheduleModal()">{{ isEmptySchedule ? 'Add Schedule' : 'Edit schedule' }}</button>
  </div>
</div>
