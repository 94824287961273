import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
  },
  {
    path: 'p',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {path: '**', redirectTo: ''},
];

const config: ExtraOptions = {
  useHash: false,
  // enableTracing: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
