<nb-card class="chutatorContainer">
  <nb-card-header style="display: flex; justify-content: space-between; align-items: center">
    <div>Template Chutator</div>
  </nb-card-header>
  <nb-card-body>
    <div>
      <form class="row" [formGroup]="templateForm">
        <div class="col-12">
          <!-- <div class="form-group">
            <label for="templateType">Tipo de Template:</label>
            <nb-select id="templateType" formControlName="templateType" required>
              <nb-option value="UTILITY">Utility</nb-option>
              <nb-option value="MARKETING">Marketing</nb-option>
            </nb-select>
          </div> -->

          <div class="form-group row">
            <nb-toggle class="col-4" formControlName="includeUrlButton">URL Button</nb-toggle>
            <div class="col-8" *ngIf="templateForm.controls.includeUrlButton.value" class="form-group">
              <div class="row">
                <label for="urlButtonUrl" class="label col-sm-3 col-form-label">URL</label>
                <div class="col-sm-9">
                  <input fullWidth nbInput type="text" id="urlButtonUrl" formControlName="urlButtonUrl" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <nb-toggle class="col-4" formControlName="includeImageHeader">Image Header</nb-toggle>
          </div>

          <div class="form-group row">
            <nb-toggle class="col-4" formControlName="includeBooklineFooter">Bookline Footer</nb-toggle>
          </div>

          <div class="form-group row">
            <nb-toggle class="col-4" formControlName="includeReservationTermsButton">Reservation terms button</nb-toggle>
          </div>
        </div>
        <!-- <div class="col-4">
          <div class="form-group">
            <label for="backend">Backend (only for card details)</label>
            <nb-select multiple fullWidth id="backend" multiple formControlName="backend" required>
              <nb-option value="covermanager">Cover Manager</nb-option>
              <nb-option value="restoo">Restoo</nb-option>
              <nb-option value="thefork">The Fork</nb-option>
              <nb-option value="reservandonos">Reservandonos</nb-option>
              <nb-option value="precompro">Precompro</nb-option>
              <nb-option value="revo">Revo</nb-option>
            </nb-select>
          </div>
        </div> -->

        <!-- <button type="submit" nbButton [disabled]="!templateForm.valid">Subir Templates</button> -->
        <div style="display: flex; justify-content: flex-start; align-items: flex-start">
          <button
            [disabled]="templateForm.controls.includeUrlButton.value && templateForm.controls.urlButtonUrl.value == ''"
            type="button"
            style="margin-bottom: 40px"
            nbButton
            (click)="getTemplates()"
          >
            Check and install
          </button>
          <div style="margin-left: 10px" *ngIf="gettingTemplates" class="spinner-container">
            <img src="../../../../assets/images/logo-ruedita.png" alt="Loading..." class="spinner-image big" />
          </div>
        </div>
      </form>
      <div class="row">
        <div *ngIf="groupedTemplatesInWaba.length > 0" class="col-6">
          <h4>Installed templates</h4>

          <table class="table table-striped">
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Languages</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let template of groupedTemplatesInWaba">
                <td style="max-width: 50%">{{ template.name }}</td>
                <td>
                  <!-- Mostrar los lenguajes separados por coma -->
                  {{ template.languages.join(', ') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="groupedTemplatesToInstall.length > 0" class="col-6">
          <h4>Missing Templates</h4>

          <table class="table table-striped">
            <thead>
              <tr>
                <th style="display: flex; align-items: center">
                  <input style="margin-right: 5px;" type="checkbox" (change)="toggleSelectAll($event)" [checked]="isAllSelected()" />
                  Select
                </th>

                <th>Template Name</th>
                <th>Languages</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let template of groupedTemplatesToInstall">
                <td>
                  <input type="checkbox" (change)="onTemplateSelect(template, $event)" [checked]="selectedTemplates.includes(template.name)" />
                </td>
                <td>{{ template.name }}</td>
                <td>
                  <!-- Mostrar los lenguajes separados por coma -->
                  {{ template.languages.join(', ') }}
                </td>
              </tr>
            </tbody>
          </table>
          <div style="display: flex; flex-direction: row-reverse">
            <button type="button" nbButton (click)="install()">Install</button>
          </div>
          <div *ngIf="uploadTemplatesFlag">
            <div style="display: flex; justify-content: flex-start">
              Uploading templates... {{ templatesRemaining }} remaining
              <div style="margin-left: 10px" *ngIf="uploadingTemplates" class="spinner-container">
                <img src="../../../../assets/images/logo-ruedita.png" alt="Loading..." class="spinner-image" />
              </div>
            </div>
            <p>{{ successfulUploads }} / {{ templatesChecked }} successful Uploads</p>
            <!-- Aquí va tu spinner -->
          </div>
          <div *ngIf="errorMessages.length > 0">
            <div *ngFor="let message of errorMessages">
              <!-- <p>⚠️ {{ message }}</p> -->
              <p [innerHTML]="message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <!-- <button nbButton status="primary" (click)="save()">Save</button> -->
    <button nbButton ghost status="primary" (click)="dismiss()">Exit</button>
  </nb-card-footer>
</nb-card>
