import {Component, HostListener} from '@angular/core';
import {NbIconLibraries} from '@nebular/theme';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack('fas', {packClass: 'fas', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('far', {packClass: 'far', iconClassPrefix: 'fa'});
    this.iconLibraries.registerFontPack('flags', {packClass: 'fi', iconClassPrefix: 'fi'});
  }

  async ngOnInit(): Promise<void> {
    this.checkForHardReload();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (event.metaKey && event.shiftKey && event.key === 'r') {
      this.setStorageItem('hardReload', 'true');
    }
  }

  checkForHardReload(): void {
    const hardReload = localStorage.getItem('hardReload');
    if (hardReload) {
      // Limpia el localStorage si se detecta un hard reload
      localStorage.clear();
      // Limpia la bandera
      localStorage.removeItem('hardReload');
    }
  }

  /**
   * Determines whether an error is a QuotaExceededError.
   *
   * Browsers love throwing slightly different variations of QuotaExceededError
   * (this is especially true for old browsers/versions), so we need to check
   * different fields and values to ensure we cover every edge-case.
   *
   * @param err - The error to check
   * @return Is the error a QuotaExceededError?
   */
  isQuotaExceededError(err: unknown): boolean {
    return (
      err instanceof DOMException &&
      // everything except Firefox
      (err.code === 22 ||
        // Firefox
        err.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        err.name === 'QuotaExceededError' ||
        // Firefox
        err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
    );
  }

  setStorageItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      if (this.isQuotaExceededError(e)) {
        return;
      }
      throw e;
    }
  }
}
