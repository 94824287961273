/* tslint:disable */
/* eslint-disable */
/**
/* This file was automatically generated from pydantic models by running pydantic2ts.
/* Do not modify it by hand - just update the pydantic models and then re-run the script
*/

export enum Partner {
  NONE = 0,
  MOCK_SERVER = 1,
  ATRAPALO = 2,
  EL_TENEDOR = 3,
  COVER_MANAGER = 4,
  MUCHACOMIDA = 5,
  RESTOO = 6,
  BOOKLINE = 7,
}
export enum ScheduleSource {
  RESTAURANT = 0,
  HANDOVER = 1,
  CUSTOM = 2,
  NONE = 3,
}
export enum VoiceProvider {
  GOOGLE = 0,
  MICROSOFT = 1,
  AMAZON = 2,
  VERBIO = 3,
  ELEVENLABS = 4,
}
export enum HandoverMusic {
  NONE = 0,
  DEFAULT = 1,
  CUSTOM = 2,
}
export enum ProposalPolicy {
  NONE = 0,
  SAME_DAY_SAME_SHIFT = 1,
  SAME_DAY = 2,
  SAME_WEEK_SAME_SHIFT = 3,
  SAME_WEEK = 4,
  GROUP = 5,
  HANDOVER = 6,
  WAITLIST = 7,
  CUSTOM_MESSAGE = 8,
}
export enum Carrier {
  UNKNOWN = 0,
  MOVISTAR = 1,
  ORANGE = 2,
  MASMOVIL = 3,
  VODAFONE = 4,
  JAZZTEL = 5,
  OTHER_PROVIDER = 6,
}
export enum Greeting {
  STANDARD = 0,
  BUSY = 1,
  RECORDING = 2,
  CUSTOM = 3,
  NONE_BOOK = 4,
}
export enum CurrentShiftPolicy {
  ALLOWED = 0,
  COME_ANYWAYS = 1,
  TRANSFER = 2,
}
export enum VoucherPolicy {
  DISABLED = 0,
  REQUEST_VOUCHER = 1,
  REDIRECT_TO_WEB = 2,
}
export enum Channel {
  EMAIL = 0,
  SMS = 1,
  WHATSAPP = 2,
  WEB = 3,
  PHONE = 4,
  TELEGRAM = 5,
  SOCIAL_NETWORKS = 6,
}
export enum SMSProvider {
  TWILIO = 0,
  NETELIP = 1,
  LCRCOM = 2,
  SMSPUBLI = 3,
  RESTOO = 4,
}
export enum EmailRestaurantNotificationsTemplate {
  DEFAULT_MAIL = 0,
  RESERVATION_APPROVAL = 1,
}
export enum AnswerType {
  OPEN = 0,
  YES_NO = 1,
  EMAIL = 2,
}
export enum QuestionType {
  DEFAULT = 0,
  CUSTOM = 1,
}
export enum ReservationExceptionType {
  DENY = 0,
  TRANSFER = 1,
}
export enum VoucherCompany {
  UNKNOWN = 0,
  RESTAURANT = 1,
}
export enum CrossSellingPolicy {
  NONE = 0,
  GEO = 1,
  PRIORITY_LIST = 2,
}
export enum SlotStatus {
  AVAILABLE = 0,
  PROPOSED = 1,
  REJECTED = 2,
  ACCEPTED = 3,
  AVAILABLE_MANUAL = 4,
}
export enum SlotType {
  NORMAL = 0,
  CANCELLATION_POLICY = 1,
  TICKET = 2,
}

export enum ProposalPolicyExceptionApplicationType {
  SCHEDULE = 0,
  RANGE = 1,
}

export enum ReservationExceptionApplicationType {
  RANGE = 0,
  SCHEDULE = 1,
}

export interface BackendConfig {
  name: string;
  user: string;
  password: string;
  time_zone: string;
  duration: number;
  custom_params: CustomParameters[];
  slack_web_hook: string;
  partner: Partner;
  google_maps_place_id: string;
  booking_schedule: Schedule;
  booking_schedule_source: ScheduleSource;
}
export interface CustomParameters {
  name: string;
  value: string;
}
export interface Schedule {
  days: {
    [k: string]: ArrayShift;
  };
  exceptions: ScheduleException[];
  always_enabled?: BoolValue;
}
export interface ArrayShift {
  shifts: Shift[];
}
export interface Shift {
  start_time: string;
  end_time: string;
}
export interface ScheduleException {
  day: Timestamp;
  shifts?: ArrayShift;
  reason: string;
}
export interface Timestamp {
  seconds: number;
  nanos: number;
}
export interface BoolValue {
  value: boolean;
}
export interface Client {
  /** Phone number of the restaurant, where the users call. */
  phone: string;
  details: ClientConfig;
  backend: BackendConfig;
  ID: string;
  owner: string;
  ivr: IVRConfig;
  ddi: string;
}
export interface ZoneAlias {
  name: string;
  alias_json: string;
  nlu_alias_list_json: string;
}
export interface ProposalPolicyException {
  policies: ProposalPolicy[];
  schedule: Schedule;
  application_date_ranges: CalendarRange[];
  application_type: ProposalPolicyExceptionApplicationType;
  use_custom_no_availability_config: boolean;
  custom_no_availability_transfer_config: TransferScheduleConfig;
}

export interface GroupReservationsBackend {
  name: string;
  user: string;
  password: string;
  time_zone: string;
  duration: number;
}

export interface RestaurantNotificationsConfig {
  enabled_channels: Channel[];
  email_addresses: string[];
  destination_phones: string[];
}

export interface UserNotificationsConfig {
  enabled_channels: Channel[];
}

export interface ReservationObservationsConfig {
  required: boolean;
  questions: ObservationQuestion[];
}

export interface ReservationZonesConfig {
  enabled: boolean;
  aliases: ZoneAlias[];
}

export interface ReservationProposalPoliciesConfig {
  policies: ProposalPolicy[];
  same_week_proposal_policy_lookaround_days: number;
  no_availability_transfer_config: TransferScheduleConfig;
  exceptions: ProposalPolicyException[];
}

export interface ReservationTermsConfig {
  notify: boolean;
  notify_before_confirmation: boolean;
  custom_message_before_terms: string;
}

export interface ReservationDurationConfig {
  notify: boolean;
  default_duration_minutes: number;
}

export interface ReservationsConfig {
  max_diners: number;
  booking_timeframe: ReservationTimeframe;
  custom_message_before_confirmation: string;
  custom_message_after_confirmation: string;
  reservation_terms: ReservationTermsConfig;
  reservation_duration: ReservationDurationConfig;
  proposal_policies: ReservationProposalPoliciesConfig;
  observations: ReservationObservationsConfig;
  zones: ReservationZonesConfig;
}

export interface HandoverConfig {
  enabled: boolean;
  phones_with_schedule: HandoverPhoneSchedule[];
  custom_message_no_handover: string;
}

export interface GroupReservationsConfig {
  enabled: boolean;
  backend: GroupReservationsBackend;
  restaurant_notifications: RestaurantNotificationsConfig;
  user_notifications: UserNotificationsConfig;
  reservations: ReservationsConfig;
  handovers: HandoverConfig;
}
export interface CustomReservationConfirmedMessages {
  normal: string;
  card_details_pending: string;
  restaurant_confirmation_pending: string;
  backend_none_restaurant_confirmation_pending: string;
  backend_none_user_email_required: string;
}

export interface BackgroundTrack {
  /** Enable or disable background track. */
  enabled: boolean;
  /**Url to the file to play in the background track*/
  file_url: string;
  /**Decide whether to loop the track or not. True by default.*/
  loop: boolean;
  /**Sound gain for the background track, in dB. Default is -9dB.*/
  gain: number;
}

/** Configure notifications to follow-up unfinished calls */
export interface FollowUpConfig {
  /**Enable/disable follow-up notifications. */
  enabled: boolean;
  /**If provided and not empty, will change the default message to one that points to this url. */
  url?: string;
  /**If provided and not empty, will change the default message. */
  message?: string;
  /**Wait a specific number of seconds before sending the notification. */
  send_after_seconds: number;
}
export interface DeliveryNotificationsConfig {
  enabled: boolean;
  url?: string;
}
export interface DeliveryHandoverConfig {
  enabled: boolean;
  custom_handover_message?: string;
  custom_no_handover_message?: string;
}
export interface MenuNotificationsConfig {
  enabled: boolean;
  url?: string;
}
export interface MenuHandoverConfig {
  enabled: boolean;
  custom_handover_message?: string;
  custom_no_handover_message?: string;
}
export interface ReservationTimeframe {
  maximum_in_advance_seconds: number;
  // "It is not possible to make a reservation with this advance notice or more"
  minimum_in_advance_seconds: number;
  // "It is not possible to make a reservation with this advance notice or less"
  custom_too_far_booking_message: string;
  custom_too_close_booking_message: string;
}

export interface ClientConfig {
  name: string;
  title: string;
  default_language: Language;
  supported_languages: Language[];
  has_parking?: BoolValue;
  has_parking_nearby?: BoolValue;
  easy_parking?: BoolValue;
  mobility_options: MobilityOptions[];
  accepted_payment_methods: PaymentMethod[];
  address: string;
  schedule: Schedule;
  web_url: string;
  nearby_parking_address: string;
  has_web?: BoolValue;
  social_networks: SocialNetwork[];
  has_handicapped_access?: BoolValue;
  has_handicapped_bathroom?: BoolValue;
  months_in_advance_limit: number;
  mail_list: string[];
  timezone: string;
  languages_spoken: string[];
  kids_accepted?: BoolValue;
  pets_accepted?: BoolValue;
  has_air_conditioner?: BoolValue;
  has_heater?: BoolValue;
  has_glutenfree_option?: BoolValue;
  has_vegan_option?: BoolValue;
  has_vegetarian_option?: BoolValue;
  has_lactoseintolerant_option?: BoolValue;
  has_kosher_option?: BoolValue;
  has_halal_option?: BoolValue;
  has_parking_ballet?: BoolValue;
  average_menu_price: number;
  has_wifi?: BoolValue;
  smoking_allowed?: BoolValue;
  has_terrace?: BoolValue;
  typical_dish: string;
  has_show?: BoolValue;
  has_microwave?: BoolValue;
  has_high_chairs?: BoolValue;
  has_birthday_celebration?: BoolValue;
  birthday_cake_accepted?: BoolValue;
  has_birthday_desert?: BoolValue;
  has_birthday_candles?: BoolValue;
  has_privatization_option?: BoolValue;
  parties_allowed?: BoolValue;
  has_pool?: BoolValue;
  has_karaoke?: BoolValue;
  has_dancefloor?: BoolValue;
  has_special_menu?: BoolValue;
  wifi_free?: BoolValue;
  shift_duration_minutes: number;
  handovers: HandoverConfig;
  // handover_schedule: HandoverPhoneSchedule[];
  handover_exceptions: HandoverException[];
  bot_schedule: Schedule;
  handover_diners_per_booking_limit: number;
  industry: string;
  pre_booking?: BoolValue;
  greeting: string;
  propose_if_busy?: BoolValue;
  referral?: BoolValue;
  phone_carrier: string;
  notification_channel: string[]; // Deprecated, left only for super retrocompatibility profile loading
  customer_service_schedule: Schedule;
  zones_enabled?: BoolValue;
  proposal_policy: ProposalPolicy[];
  carrier: Carrier;
  greeting_type: Greeting;
  current_shift_policy: CurrentShiftPolicy;
  voucher_policy: VoucherPolicy;
  restaurant_notifications: Channel[];
  client_notifications: Channel[];
  contact_unavailable_policy: ContactPolicy[];
  gdpr?: BoolValue;
  voicemail?: BoolValue;
  vm_email_list: string[];
  voice_provider: VoiceProvider;
  observations_required?: BoolValue;
  transfer_popup?: BoolValue;
  formality?: BoolValue;
  custom_proposal_message: string;
  custom_greeting_message: string;
  duration_notification?: BoolValue;
  language_detection?: BoolValue;
  retention_period: number;
  accepts_reservations?: BoolValue;
  accepts_delivery?: BoolValue;
  custom_out_of_schedule_message: string;
  address_lat: string;
  address_lon: string;
  email_validation?: BoolValue;
  telegram_phones: string[];
  email_validation_mails: string[];
  delivery_custom_message: string;
  name_greeting?: BoolValue;
  custom_message_before_confirmation: string;
  lead_demo_on_slack?: BoolValue;
  sms_provider: SMSProvider;
  whatsapp_sender: string;
  twilio_sid: string;
  twilio_token: string;
  reservations_timeframe: number;
  check_weather?: BoolValue;
  special_reservation_terms?: BoolValue;
  ask_for_stored_name_if_recurring_customer?: BoolValue;
  too_many_diners_transfer_policy?: BoolValue;
  custom_message_too_many_diners_no_transfer: string;
  custom_message_too_many_diners_with_transfer: string;
  listen_dtmf_digits?: BoolValue;
  email_restaurant_notifications_template: EmailRestaurantNotificationsTemplate;
  custom_message_reservation_in_current_shift: string;
  conversation_motive: string;
  custom_greting_message: string;
  custom_greeting_url: string;
  custom_greeting_notes: string;
  use_part_of_day_in_hours: boolean;
  gather_email_from_past_reservations: boolean;
  customer_service_schedule_source: ScheduleSource;
  no_availability_transfer_config: TransferScheduleConfig;
  same_week_proposal_policy_lookaround_days: number;
  whatsapp_phones: string[];
  notify_reservation_terms_before_confirmation: boolean;
  custom_message_before_reservation_terms: string;
  observation_questions: ObservationQuestion[];
  ms_delay_first_interaction: number;
  custom_message_after_confirmation: string;
  diversion_type: string[];
  whatsapp_bookline_marketing: boolean;
  basic_information_notes: string;
  whatsapp_bookline_marketing_image_url: string;
  // custom_handover_disabled_message: string;
  cross_selling_group_id: string;
  cross_selling_bots: CrossSellingBotData[];
  prebooking_expiration_minutes: number;
  reservation_exceptions: ReservationExceptionConfig[];
  send_whatsapp_disregard_user_email: boolean;
  zone_aliases: ZoneAlias[];
  mixpanel_dashboard_link: string;
  current_shift_policy_schedule: Schedule;
  requires_email_via_whatsapp: boolean;
  proposal_policy_exceptions: ProposalPolicyException[];
  maximum_days_in_advance_reservation: number;
  group_reservations: GroupReservationsConfig;
  restaurant_language: Language;
  custom_reservation_confirmed_messages: CustomReservationConfirmedMessages;
  allow_greeting_interrupt: boolean;
  custom_transfer_recovery_message: string;
  manage_covermanager_reservations_no_bookline: boolean;
  country: string;
  /**Seconds to pause before the greeting is said. */
  pre_greeting_pause: number;
  /**Background track configuration. */
  background_track: BackgroundTrack;
  /**Boost (in dB) to apply to the generated sound of this bot. */
  audio_boost: number;
  /**Configuration for notifications to follow-up unfinished calls. */
  followup_config: FollowUpConfig;
  delivery_handover_config: DeliveryHandoverConfig;
  delivery_notifications_config: DeliveryNotificationsConfig;
  menu_handover_config: MenuHandoverConfig;
  menu_notifications_config: MenuNotificationsConfig;
  time_period_greeting: BoolValue;
  booking_timeframe: ReservationTimeframe;
  custom_no_recent_appointment_message: string;
  default_motive: string;
  ask_email_after_reservation_config: AskEmailAfterReservationConfig;
  event_config: EventConfig;
  event_notifications_config: EventNotificationsConfig;
}
export interface Language {
  title: string;
  language_code: string;
  voice: string;
  voice_provider: VoiceProvider;
  enabled?: BoolValue;
  voice_speed: number;
  volume: string;
}
export interface MobilityOptions {
  title: string;
  stops: Stop[];
}
export interface AskEmailAfterReservationConfig {
  enabled?: boolean;
  send_after_seconds: number;
}

export interface EventReservationsBackend {
  name?: string;
  user?: string;
  password?: string;
  time_zone?: string;
  duration?: number;
}

export interface EventReservationsConfig {
  enabled?: boolean;
  backend?: EventReservationsBackend;
  restaurant_notifications?: RestaurantNotificationsConfig;
  user_notifications?: UserNotificationsConfig;
  reservations?: ReservationsConfig;
  handovers?: HandoverConfig;
}

export interface EventNotificationsConfig {
  enabled?: BoolValue;
  url?: string;
}
export interface EventConfig {
  enabled?: boolean;
  reservations_config?: EventReservationsConfig;
  custom_handover_message?: string;
  custom_no_handover_message?: string;
  events_info_message?: string;
}
export interface RestaurantNotificationsConfig {
  name?: string;
}
export interface Stop {
  name: string;
  line: string;
}
export interface PaymentMethod {
  title: string;
}
export interface SocialNetwork {
  username: string;
  title: string;
}
export interface HandoverException {
  name: string;
  handover_schedule_exceptions: HandoverPhoneSchedule[];
  date_range: CalendarRange[];
}
export interface HandoverPhoneSchedule {
  phone: string;
  schedule: Schedule;
  name: string;
  context: string;
  phonetics: string[];
  custom_name: string;
  priority: number;
  operator: string;
  caller_id: string;
  handover_music_type: HandoverMusic;
  custom_handover_music_url: string;
  whisper_message: boolean;
  custom_whisper_message: string;
}
export interface ContactPolicy {
  type: Channel;
  value: string;
}
export interface TransferScheduleConfig {
  transfer_call: boolean;
  schedule_source: ScheduleSource;
  custom_transfer_schedule: Schedule;
  custom_message_transfer: string;
  custom_message_no_transfer: string;
}
export interface ObservationQuestion {
  question_json: string;
  observation_header: string;
  answer_type: AnswerType;
  question_type: QuestionType;
  question_id: number;
}
export interface CrossSellingBotData {
  bot_id: string;
  message: string;
}
export interface ReservationExceptionConfig {
  application_date_ranges: CalendarRange[];
  exception_type: ReservationExceptionType;
  exception_message_json: string;
  application_schedule: Schedule;
  application_type: ReservationExceptionApplicationType;
}
export interface CalendarRange {
  start: CalendarDateTime;
  end: CalendarDateTime;
}
export interface CalendarDateTime {
  date: CalendarDate;
  time: HourMinute;
}
export interface CalendarDate {
  year: number;
  month: number;
  day: number;
}
export interface HourMinute {
  hour: number;
  minute: number;
}
export interface IVRConfig {
  enabled?: BoolValue;
  steps: IVRStep[];
  white_list: string[];
  black_list: string[];
}
export interface IVRStep {
  name: string;
  type: string;
  prompt: string;
  promptAudioURL: string;
  options: IVRStepOption[];
  promptSynthJSON: string;
  timeout: number;
  pre_prompt_pause: number;
}
export interface IVRStepOption {
  promptNumber: number;
  promptValue: string;
  promptParameters: string;
  promptVoiceAlternatives: string[];
  customPrompt: string;
  action: string;
  actionParameters: string[];
  nextStep: number;
  customSms: boolean;
}
export interface Customer {
  phone: string;
  country_code: string;
  name: string;
  last_name: string;
  language: string;
  email: string;
  newsletter_subscription?: BoolValue;
}
export interface InsertClientRequest {
  ddi: string;
  client: Client;
}
export interface Appointment {
  id: string;
  appointment_time: Timestamp;
  duration: Duration;
  customer: Customer;
  restaurant: RestaurantAppointment;
  dentist: DentistAppointment;
  comments: string;
  voucher: DiningVoucher;
  state: string;
  source: string;
  cancellation_policy: boolean;
  cover_date_overflow: boolean;
}
export interface Duration {
  seconds: number;
  nanos: number;
}
export interface RestaurantAppointment {
  diners: number;
  zone: Zone;
}
export interface Zone {
  id: string;
  name: string;
}
export interface DentistAppointment {
  dentist: Member;
  priority: number;
}
export interface Member {
  name: string;
}
export interface DiningVoucher {
  provider: VoucherCompany;
  redemption_code: string;
}
export interface BillingInfo {
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  last_four: number;
  state: string;
  country: string;
  zip: string;
  vat_number: string;
  ip_address: string;
  ip_address_country: string;
  first_six: number;
  card_type: string;
  month: number;
  year: number;
  paypal_billing_agreement_id: string;
  name_on_account: string;
  account_number: number;
  updated_at: Timestamp;
}
export interface CancellationPolicy {
  advance_notice_hours: number;
  penalty_fee_per_person: number;
}
export interface ClientAccount {
  uuid: string;
  clients: string[];
  billing: BillingInfo;
}
export interface ClientBasicInfo {
  ID: string;
  name: string;
  title: string;
  phone: string;
  backend_slug: string;
}
export interface ClientGroup {
  ID: string;
  name: string;
  title: string;
  restaurants: ClientBasicInfo[];
  cross_selling_policy: CrossSellingPolicy;
  default_handover: string;
  priorities: string[];
  handover_options: HandoverPhoneSchedule[];
}
export interface DDIConfig {
  ddi: string;
  start: Timestamp;
  end: Timestamp;
}
export interface DDIRequest {
  country_code: string;
  number: string;
}
export interface DeleteAppointmentRequest {
  ddi: string;
  phone: string;
  id: string;
}
export interface DeletePendingAppointmentRequest {
  client_id: string;
  phone: string;
  appointment_id: string;
}
export interface DeleteRecordingRequest {
  id: string;
}
export interface Entity {
  type: string;
  name: string;
}
export interface Event {
  category: string;
  action: string;
  text: string;
  num: number;
  date: Timestamp;
  option: boolean;
  interaction: Interaction;
  value_list: string[];
}
export interface Interaction {
  user: string;
  bot: string;
  date: Timestamp;
  duration: number;
  final_status: string;
  final_action: string;
  intent: string;
  entities: Entity[];
  client_slots: SlotOverview[];
}
export interface SlotOverview {
  name: string;
  slots: Slot[];
}
export interface Slot {
  time_min: Timestamp;
  time_max: Timestamp;
  num_diners: number;
  zone: Zone;
  notify_duration: boolean;
  status: SlotStatus;
  restaurant: string;
  cancellation_policy?: BoolValue;
  time_min_str: string;
  time_max_str: string;
  cover_date_overflow: boolean;
}
export interface EventList {
  events: Event[];
}
export interface GetClientConfigRequest {
  ddi: string;
}
export interface GetClientGroupRequest {
  id: string;
}
export interface GetClientRequest {
  ddi: string;
}
export interface GetCustomerRequest {
  ddi: string;
  phone: string;
  country_code: string;
}
export interface GetHandoverPhoneRequest {
  ddi: string;
}
export interface GetWeatherRequest {
  ddi: string;
  request: WeatherRequest;
}
export interface WeatherRequest {
  timestamp: Timestamp;
}
export interface GetZonesRequest {
  ddi: string;
  request: ZoneRequest;
}
export interface ZoneRequest {
  time: Timestamp;
  num_diners: number;
  language: string;
}
export interface HandoverPhone {
  Phone: string;
}
export interface HealthRequest {}
export interface HealthResponse {
  status: string;
}
export interface InsertAppointmentBody {
  appointment: Appointment;
  country_id: string;
  cross_selling: boolean;
}
export interface InsertAppointmentRequest {
  ddi: string;
  phone: string;
  body: InsertAppointmentBody;
  bot_id: string;
  language: string;
}
export interface InsertCustomerEmailBody {
  email: string;
}
export interface InsertCustomerEmailRequest {
  ddi: string;
  phone: string;
  country_code: string;
  body: InsertCustomerEmailBody;
  name: string;
  last_name: string;
}
export interface IntegrationHealthRequest {
  ddi: string;
}
export interface ListAppointmentsRequest {
  ddi: string;
  phone: string;
  country_code: string;
  timeframe_days: number;
}
export interface Mail {
  name: string;
  email: string;
  message: string;
}
export interface MailResponse {
  mail: string;
}
export interface PopUp {
  ddi: string;
}
export interface PopUpRequest {
  ddi: string;
  phone: string;
  operator: string;
}
export interface PopUpRequestLCR {
  body: PopUpRequest;
}
export interface SendCallEventRequest {
  call: string;
  event: Event;
}
export interface SendCallEventsRequest {
  call: string;
  events: EventList;
}
export interface SetDDIRequest {
  number: string;
  body: DDIRequest;
}
export interface SlotDetails {
  available: boolean;
  type: SlotType;
  cancellation_policy: CancellationPolicy;
  confirmation_policy: boolean;
}
export interface SlotDetailsRequest {
  ddi: string;
  datetime: Timestamp;
  num_diners: number;
  options: SlotDetailsRequestOptions;
  bot_id: string;
  cover_date_overflow: boolean;
}
export interface SlotDetailsRequestOptions {
  datetime: Timestamp;
  num_diners: number;
  datetime_str: string;
}
export interface SlotMessage {
  message: string;
}
export interface SlotMessageRequest {
  ddi: string;
  time_min: Timestamp;
  time_max: Timestamp;
  num_diners: number;
  language: string;
  extra_zone: string;
}
export interface SlotRequest {
  ddi: string;
  time_min: Timestamp;
  time_max: Timestamp;
  num_diners: number;
  cross_selling: boolean;
  refresh: boolean;
  language: string;
  options: SlotRequestOptions;
}
export interface SlotRequestOptions {
  time_min: Timestamp;
  time_max: Timestamp;
  num_diners: number;
  cross_selling: boolean;
  language: string;
  refresh: boolean;
  time_min_str: string;
  time_max_str: string;
}
export interface UpdateAppointmentRequest {
  ddi: string;
  phone: string;
  id: string;
  body: InsertAppointmentBody;
}
export interface WeatherResponse {
  timestamp: Timestamp;
  name: string;
  feels_like: number;
}
