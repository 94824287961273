<nb-card>
  <nb-card-header style="display: flex; justify-content: space-between; align-items: center"
    >
    <div>New venue</div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="mainForm">
      <div class="form-group">
        <!-- ID -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">ID<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <input
                type="text"
                nbInput
                fullWidth
                placeholder="ID"
                formControlName="id"
                disabled
              />
          </div>
        </div>
        <!-- Name -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Name<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <input
                type="text"
                nbInput
                fullWidth
                placeholder="Name"
                formControlName="name"
                autofocus
              />
          </div>
        </div>
        <!-- Type -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Type<span class="mandatory">*</span></label>
          <div class="col-sm-8">
            <nb-select formControlName="type" fullWidth placeholder="Select a type">
              <nb-option *ngFor="let vt of venueTypes" [value]="vt">{{ vt }}</nb-option>
            </nb-select>
          </div>
        </div>
        <!-- Owner id -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Customer ID<span class="mandatory">*</span></label>
          <div class="col-sm-7">
            <input #autoInput nbInput type="text" fullWidth formControlName="customer_id" (input)="onChange()" placeholder="Select customer id" [nbAutocomplete]="auto" />
            <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
              <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.id"> {{ option.name }} ({{ option.id }}) </nb-option>
            </nb-autocomplete>
          </div>
          <div *ngIf="isVenueSet()" class="col-sm-1">
            <button nbButton outline fullWidth (click)="goToCustomer()"><nb-icon icon="external-link-outline" status="info"></nb-icon></button>
          </div>
          <div *ngIf="!isVenueSet()" class="col-sm-1">
            <button nbButton outline fullWidth (click)="createCustomer()"><nb-icon icon="plus-outline" status="info"></nb-icon></button>
          </div>
        </div>
        <!-- CRM ID -->
        <div class="row slot-row">
          <label class="label col-sm-3 col-form-label" for="">Salesforce ID</label>
          <div class="col-sm-8">
            <input
                type="text"
                nbInput
                fullWidth
                placeholder="Salesforce ID"
                formControlName="crm_id"
              />
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" (click)="save()" [disabled]="submitting" [nbSpinner]="submitting">Save</button>
    <button nbButton ghost status="primary" (click)="dismiss()" [disabled]="submitting">Cancel</button>
  </nb-card-footer>
</nb-card>
