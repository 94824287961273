import {inject, Injectable} from '@angular/core';
import {signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, fetchSignInMethodsForEmail, Auth} from '@angular/fire/auth';
import {PlatformService} from '../api/platform.service';

@Injectable()
export class AuthService {
  public auth: Auth = inject(Auth);

  constructor(private ps: PlatformService) {}

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new FacebookAuthProvider(); // Proveedor de Facebook

      signInWithPopup(this.auth, provider).then(
        (res) => {
          return fetchSignInMethodsForEmail(this.auth, res.user.email).then((methods) => {
            if (!methods.includes('password')) {
              res.user.delete(); // Elimina el usuario si no hay métodos de autenticación con contraseña
              reject({code: 'auth/no-user-account'});
            } else {
              resolve(res);
            }
          });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  signUpFacebook() {
    return new Promise<any>((resolve, reject) => {
      const provider = new FacebookAuthProvider();
      provider.addScope('manage_pages');
      provider.addScope('pages_messaging');

      signInWithPopup(this.auth, provider).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      signInWithPopup(this.auth, provider).then(
        (res) => {
          return fetchSignInMethodsForEmail(this.auth, res.user.email).then((methods) => {
            if (!methods.includes('password')) {
              reject({code: 'auth/no-user-account'});
            } else {
              resolve(res);
            }
          });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, value.email, value.password).then(
        (res) => {
          resolve(res);
        },
        (err) => reject(err)
      );
    });
  }

  doLogin(value, linkWithFacebook, pendingCred) {
    console.log('intenta hacer login');

    return new Promise<any>((resolve, reject) => {
      console.log('entra aca');

      if (value.email.endsWith('@bookline.io') || value.email.endsWith('@bookline.ai')) {
        signInWithEmailAndPassword(this.auth, value.email, value.password).then(
          async (res) => {
            const user = await this.ps.getUser(res.user.uid).toPromise();
            if (user?.role === 'ADMIN') {
              resolve(res);
            } else {
              signOut(this.auth); // Cierra la sesión del usuario si no es admin
              reject('Not admin');
            }
          },
          (err) => reject(err)
        );
      } else {
        reject('unauthorized');
      }
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (this.auth.currentUser) {
        signOut(this.auth)
          .then(() => {
            resolve(0);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject();
      }
    });
  }
}
