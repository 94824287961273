import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-select-value-from-list-dialog',
  templateUrl: './select-value-from-list-dialog.component.html',
  styleUrls: ['./select-value-from-list-dialog.component.scss'],
})
export class SelectValueFromListDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() values: any[];

  constructor(protected ref: NbDialogRef<SelectValueFromListDialogComponent>) {}

  selectedValue: any;

  ngOnInit() {
    if (this.values == null || this.values.length == 0) {
      this.dismiss();
    }
    this.selectedValue = this.values[0];
  }

  confirmChoice() {
    this.ref.close(this.selectedValue);
  }

  dismiss() {
    this.ref.close(null);
  }

  ngOnDestroy() {}
}
