import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {toFirebaseObject, fromFirebaseObject} from '../data/utils';
import {take, tap, map, filter} from 'rxjs/operators';
import * as moment from 'moment';
import {Schedule, ScheduleException, Shift} from '../data/client.model';
import {RestaurantBotConfig, PlatformService} from '../../@core/api/platform.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  constructor(private papi: PlatformService) {}

  // public getClients(): Observable<any> {
  //   return this.db.collection<any>('Clients').valueChanges();
  // }

  public getRestaurantBotConfig(botId: string): Observable<{config: RestaurantBotConfig; owner_id: string} | string> {
    const response = this.papi.getBot(botId, false);
    return response.pipe(
      map((source) => {
        if (source.status == 200) {
          const result = source.body;
          if (result.config.bot_type != 'RESTAURANT') {
            return 'Bot: ' + botId + ' is of type: ' + result.config.bot_type;
          }
          return {config: result.config, owner_id: result.owner_id};
        }
        return source.statusText;
      })
    );
  }

  // public getClientByDdi(documentId: string): Observable<any> {
  //   return this.db.collection('Clients', ref => ref.where('Ddi', '==', documentId)).valueChanges()
  //   .pipe(map((client: any[]) => fromFirebaseObject(client[0])),
  //   );
  // }

  // public getUserClients(): Observable<any> {
  //   return this.db.collection<any>('Clients').valueChanges()
  //     .pipe(map((val: any[]) => val.map(v => {
  //       return {id: v['Id'], name: v['Details']['Name']};
  //     })));
  // }

  // public getClientsByOwner(owner?: string): Observable<any> {
  //   return this.papi.getBotIdsAndNames(undefined, owner)
  //       .pipe(take(1))
  //       .pipe(map((response) => {return response.data}));
  // }

  // public getClientsWithoutOwner(): Observable<any> {
  //   return this.db.collection<any>('Clients').valueChanges()
  //       .pipe(map((val: any[]) => val.filter(v => {
  //       if (!v['Owner'] || v['Owner'] === '') return true;
  //       }).map(v => {
  //         return {id: v['Id'], name: v['Details']['Name']};
  //       })));
  // }

  // public deleteClient(documentId: string) {
  //   return this.db.collection('Clients').doc(documentId).delete();
  // }

  private shiftsToString(shifts: Shift[]) {
    let shiftString = '';
    for (const shift of shifts) {
      if (shiftString !== '') {
        shiftString += ', ';
      }
      shiftString += shift.start_time + ' - ' + shift.end_time;
    }
    return shiftString;
  }

  /**
   * Generate a day-shift string mapping from a schedule
   * @param schedule
   * @returns A mapping of 0Monday, 1Tuesday... to a string representation of that day's shifts
   */
  public getScheduleDescriptor(schedule: Schedule): {[k: string]: string} {
    if (!schedule) {
      return {};
    }
    const description: {[k: string]: string} = {};
    let i = 0;
    this.days.forEach((day) => {
      if (schedule.days[day]) {
        description[i.toString() + day[0].toUpperCase() + day.slice(1)] = this.shiftsToString(schedule.days[day].shifts);
        i++;
      }
    });
    return description;
  }

  public isScheduleEmpty(schedule: Schedule): boolean {
    if (!schedule || !schedule.days) {
      return true;
    }
    let oneDayOpen = false;
    this.days.forEach((day) => {
      if (schedule.days[day]) {
        schedule.days[day].shifts.forEach((shift) => {
          oneDayOpen = oneDayOpen || (shift.start_time !== '' && shift.end_time !== '');
        });
      }
    });
    return !oneDayOpen;
  }

  public isExceptionsEmpty(exceptions: ScheduleException[]): boolean {
    if (!exceptions) return true;
    return exceptions.length === 0;
  }

  public getExceptionDescriptor(exceptions: ScheduleException[]): string[] {
    if (!exceptions) return [''];
    if (Object.keys(exceptions).length === 0) return [''];
    return Object.keys(exceptions)
      .map((key) => exceptions[key])
      .sort((a: ScheduleException, b: ScheduleException) => a.day.seconds - b.day.seconds)
      .map((val: ScheduleException) => val.reason + ' (' + moment(val.day.seconds * 1000).format('DD/MM/YYYY') + '): ' + this.shiftsToString(val.shifts.shifts));
  }
}
