import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {UserService} from '../../../@core/firestore/user.service';
import {FirebaseUserModel} from '../../../@core/data/user.model';

@Injectable()
export class UserResolver implements Resolve<FirebaseUserModel> {
  constructor(public userService: UserService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Promise<FirebaseUserModel> {
    const user = new FirebaseUserModel();
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (res) => {
          const providers = [];
          let index = 0;
          for (const value of res.providerData) providers.push(value.providerId);
          if (providers.includes('facebook.com')) index = providers.indexOf('facebook.com');
          else if (providers.includes('password')) index = providers.indexOf('password');

          if (res.providerData[index].photoURL) user.image = res.providerData[index].photoURL;
          else user.image = 'https://picsum.photos/400/300';
          user.name = res.providerData[index].displayName;
          user.provider = res.providerData[index].providerId;
          return resolve(user);
        },
        (err) => {
          if (route.routeConfig.path === 'auth') {
            return resolve(null);
          }
          this.router.navigate(['/auth/login']);
          return reject(err);
        }
      );
    });
  }
}
