import {inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Auth, onAuthStateChanged} from '@angular/fire/auth';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private auth: Auth = inject(Auth);

  constructor(public userService: UserService, private router: Router) {
    console.log('#Auth Guard - Init', this.auth);
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          this.router.navigate(['/user']);
          resolve(false); // Usuario autenticado, navega a la página de usuario
        } else {
          resolve(true); // No autenticadox, permitir el acceso
        }
      });
    });
  }
}
